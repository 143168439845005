import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchLeaveRecords } from '../../actions/leave';
import Spinner from '../layout/Spinner';

const LeaveRecords = ({ fetchLeaveRecords, leave }) => {
  const { userLeaves = [], allCompanyLeaves = [], otherLeaves = [], loading = false } = leave.leaves || {};
  const [filterStatus, setFilterStatus] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page

  useEffect(() => {
    fetchLeaveRecords();
  }, [fetchLeaveRecords]);

  // Reset currentPage to 1 when filterStatus changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filterStatus]);

  // Scroll to the top whenever the page changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Combine userLeaves and allCompanyLeaves into one array
  const combinedLeaves = useMemo(() => {
    return [...otherLeaves];
  }, [otherLeaves]);

  // Filter leaves based on the selected status
  const filteredLeaves = useMemo(() =>
    combinedLeaves.filter(
      leave => filterStatus === 'All' || leave.status.toLowerCase() === filterStatus.toLowerCase()
    ), [combinedLeaves, filterStatus]
  );

  // Sort leaves by createdAt in descending order
  const sortedLeaves = useMemo(() =>
    filteredLeaves.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    ), [filteredLeaves]
  );

  // Calculate the number of pages needed
  const totalPages = Math.ceil(sortedLeaves.length / itemsPerPage);

  // Calculate the index range of items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedLeaves.slice(indexOfFirstItem, indexOfLastItem);

  if (loading) {
    return <Spinner />;
  }

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <Fragment>
      <main className="lr-main roboto-regular">
        <div>
          <select
            className="dropdown-btn"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>

          {currentItems.length > 0 ? (
            <ol className="leave-list">
              {currentItems.map((leave, index) => (
                <li key={leave._id} className="lr-li">
                  <div className="lr-content">
                    <div>
                    <div className="lr-status">
                        <div
                          className="roboto-bold"
                          style={{
                            backgroundColor:
                              leave.status === 'Approved'
                                ? '#4CAF50' // Green
                                : leave.status === 'Pending'
                                ? '#FFC107' // Yellow
                                : leave.status === 'Rejected'
                                ? '#d13e38' // Red
                                : 'initial', // Default background color
                            color: leave.status === 'Rejected' ? '#EEEEEE' : '#000000',
                          }}
                        >
                          {leave.status}
                        </div>
                      </div>
                      <br/><br/>

                      <div className="lr-content-input">
                        <span className="lr-c-i-title">{leave.user.name}</span>
                      </div>
                      
        
                      <div className="lr-content-input">
                        <span className="lr-c-i-title">Type of Leave: </span>
                        <span className="lr-c-i-desc">{leave.leaveType}</span>
                      </div>

                      <div className="lr-content-input">
                        <div>
                          <span className="lr-c-i-title">From:</span>
                          <span className="lr-c-i-desc">{new Date(leave.dateFrom).toLocaleDateString('en-GB')}</span>
                        </div>
                        <div>
                          <span className="lr-c-i-title">To:</span>
                          <span className="lr-c-i-desc">{new Date(leave.dateTo).toLocaleDateString('en-GB')}</span>
                        </div>
                      </div>

                      <div className="lr-content-input">
                        <span className="lr-c-i-title">Number of Days: </span>
                        <span className="lr-c-i-desc">
                          {leave.daysBetween === 0.5 ? 'Half Day' : `${leave.daysBetween} ${leave.daysBetween === 1 ? 'day' : 'days'}`}
                        </span>
                      </div>

                      {leave.halfDayOption && (
                        <div className="lr-content-input">
                          <span className="lr-c-i-title">From: </span>
                          <span className="lr-c-i-desc">{leave.halfDayOption}</span>
                        </div>
                      )}

                      <div className="lr-content-input">
                        <span className="lr-c-i-title">Reason: </span>
                        <span className="lr-c-i-desc">{leave.reason}</span>
                      </div>

                      <div className="lr-content-input">
                        <span className="lr-c-i-title">Applied on: </span>
                        <span className="lr-c-i-desc">{new Date(leave.createdAt).toLocaleDateString('en-GB')}</span>
                      </div>

                    </div>
                  </div>
                </li>
              ))}
            </ol>
          ) : (
            <p className="nl-alert">No {filterStatus} Leave available.</p>
          )}

          {/* Pagination Controls */}
          <div className="pagination">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="pg-action-btn"
              aria-label="Previous page"
            >
              &#x27F5;
            </button>
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={number === currentPage ? 'active' : ''}
                aria-label={`Page ${number}`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pg-action-btn"
              aria-label="Next page"
            >
              &#x27F6;
            </button>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  leave: state.leave
});

export default connect(mapStateToProps, { fetchLeaveRecords })(LeaveRecords);
