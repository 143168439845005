import {
    FETCH_PUBLIC_HOLIDAYS,
    FETCH_PH_ERROR,
    FETCH_PENDING_LEAVES,
    FETCH_LEAVE_RECORDS,
    FETCH_LEAVES_ERROR
} from '../actions/types';

const initialState = {
    leaves: [],
    publicHolidays: [],
    loading: true,
    error: null
};

const leaveReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
      case FETCH_PUBLIC_HOLIDAYS:
        return {
            ...state,
            publicHolidays: payload,
            loading: false,
            error: null
        };
      case FETCH_PENDING_LEAVES:
      case FETCH_LEAVE_RECORDS:
      return {
          ...state,
          leaves: payload,
          loading: false,
          error: null
      };
      case FETCH_PH_ERROR:
      case FETCH_LEAVES_ERROR:
        return {
            ...state,
            error: payload,
            loading: false
        };
      default:
        return state;
    }
};

export default leaveReducer;
