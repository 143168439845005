import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import { FaRegBuilding, FaRegImage } from 'react-icons/fa';
import { IoMdCloseCircleOutline } from 'react-icons/io';

const CompanyProfile = ({ company }) => {
    const [formData, setFormData] = useState({
        about: ''
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isIconModalOpen, setIsIconModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadFeedback, setUploadFeedback] = useState('');

    useEffect(() => {
        if (company) {
            setFormData({
                about: company.about || '',
            });
        }
    }, [company]);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handlePersonalDetails = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const res = await axios.put('/api/company', formData, config);

            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('Company details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update company details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors);
            } else {
                console.error('Error updating company details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    
    const openModal = () => {
        setIsModalOpen(true);
        setUploadFeedback(''); // Clear any previous feedback
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedFile(null);
    };

    const openIconModal = () => {
        setIsIconModalOpen(true);
        setUploadFeedback('');
    };
    
    const closeIconModal = () => {
        setIsIconModalOpen(false);
        setSelectedFile(null);
    };

    const handleImageUpload = async () => {
        if (!selectedFile) {
            setUploadFeedback('Please select an image file to upload.');
            return;
        }
    
        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);
    
        const formData = new FormData();
        formData.append('image', selectedFile);
    
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        };
    
        try {
            const response = await axios.put('/api/company/image', formData, config);
            console.log('Backend Response:', response); // Log the response
    
            if (response.status === 200) {
                setUploadFeedback('Image updated successfully. This window will close automatically in 3 seconds.');
                // Close the modal after 3 seconds
                setTimeout(() => {
                    closeModal();
                }, 3000);
            } else {
                setUploadFeedback('Failed to update image.');
            }
        } catch (error) {
            console.error('Error:', error);
            setUploadFeedback('An error occurred during the upload. Please try again.');
        }
    };

    const handleIconUpload = async () => {
        if (!selectedFile) {
            setUploadFeedback('Please select an image file to upload.');
            return;
        }
    
        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);
    
        const formData = new FormData();
        formData.append('image', selectedFile);
    
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        };
    
        try {
            const response = await axios.post('/api/company/logo', formData, config);
            console.log('Backend Response:', response); // Log the response
    
            if (response.status === 200) {
                setUploadFeedback('Icon updated successfully. This window will close automatically in 3 seconds.');
                // Close the modal after 3 seconds
                setTimeout(() => {
                    closeIconModal();
                }, 3000);
            } else {
                setUploadFeedback('Failed to update icon.');
            }
        } catch (error) {
            console.error('Error:', error);
            setUploadFeedback('An error occurred during the upload. Please try again.');
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">
                Company Details <FaRegBuilding />
            </div>
            <div className="ep-li-desc">
                We use this information to populate your company online identity across all our associated platforms making your business more visible on the internet
            </div>

            <div className="ecp-updateimg">
                <div className="ecp-updateimg-img">
                    <img 
                        src={company.imageUrl || 'https://placehold.co/800x400/orange/white?text=Cover+Image'} 
                        alt="Company Cover" 
                        className="ep-avatar-img"
                    />
                </div>
                <div>
                    <button className="ep-updateimg-btn" onClick={openModal}>Update Cover</button>

                    {isModalOpen && (
                        <div className="ecp-modal-overlay">
                            <div className="ecp-modal-content">
                                <div><FaRegImage className='mc-icon'/></div>
                                <div>
                                    <input type="file" accept="image/*" onChange={handleFileChange} />
                                    <button onClick={handleImageUpload} className="ep-update-btn">Upload</button>
                                    <button onClick={closeModal} className="ep-cancel-btn">
                                        <IoMdCloseCircleOutline />
                                    </button>
                                    <br/><br/>
                                    {uploadFeedback && <span className="upload-feedback">{uploadFeedback}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="ecp-updateicon">
                <div className="ecp-updateimg-icon">
                    <img 
                        src={company.iconUrl || 'https://placehold.co/300x200/blue/white?text=Company+Icon'} 
                        alt="Company Icon" 
                        className="ep-avatar-img"
                    />
                </div>
                <div>
                    <button className="ep-updateimg-btn" onClick={openIconModal}>Update Company Icon</button>

                    {isIconModalOpen && (
                        <div className="ecp-modal-overlay">
                            <div className="ecp-modal-content">
                                <div><FaRegImage className='mc-icon'/></div>
                                <div>
                                    <input type="file" accept="image/*" onChange={handleFileChange} />
                                    <button onClick={handleIconUpload} className="ep-update-btn">Upload</button>
                                    <button onClick={closeIconModal} className="ep-cancel-btn">
                                        <IoMdCloseCircleOutline />
                                    </button>
                                    <br/><br/>
                                    {uploadFeedback && <span className="upload-feedback">{uploadFeedback}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <form className="ep-form" onSubmit={handlePersonalDetails}>
                <label htmlFor="about">About:</label>
                <textarea 
                    id="about" 
                    name="about" 
                    value={formData.about} 
                    onChange={onChange} 
                    placeholder='Describe what you do in the simplest terms. Sharing the problems you solve, for whom, and how is a great way to demonstrate your skills, industry knowledge, and work style. For inspiration, ask yourself: Who are you helping when you do your job?' 
                    className='ep-about-textarea'
                />
                <div className='m-y-divider'></div>
                <button type="submit" className='ep-btn'>Update Company Details</button>
            </form>

            <div className='m-y-divider'></div>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CompanyProfile;
