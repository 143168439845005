import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaRegImage } from "react-icons/fa6";

const UpdateProfile = ({ profile, user }) => {
    const [about, setAbout] = useState(profile?.about || '');
    const [education, setEducation] = useState(profile?.education || []);
    const [career, setCareer] = useState(profile?.career || []);
    const [location, setLocation] = useState(profile?.location || '');
    const [social, setSocial] = useState(profile?.social || {
        facebook: '',
        instagram: '',
        linkedin: '',
        youtube: ''
    });
    const [expandedEducationIndex, setExpandedEducationIndex] = useState(null);
    const [expandedCareerIndex, setExpandedCareerIndex] = useState(null);
    const [tempEducation, setTempEducation] = useState({
        school: '',
        degree: '',
        fieldOfStudy: '',
        from: '',
        to: '',
        current: false,
        description: ''
    });
    const [tempCareer, setTempCareer] = useState({
        company: '',
        position: '',
        from: '',
        to: '',
        current: false,
        description: ''
    });

    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadFeedback, setUploadFeedback] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('/path-to-default-avatar.jpg');


    useEffect(() => {
        loadAvatar();
        if (profile) {
            setAbout(profile.about || '');
            setEducation(profile.education || []);
            setCareer(profile.career || []);
            setLocation(profile.location || '');
            setSocial(profile.social || {
                facebook: '',
                instagram: '',
                linkedin: '',
                youtube: ''
            });
        }
    }, [profile]);

     // Function to load avatar
     const loadAvatar = () => {
        if (user) {
            if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
                // Use the Gravatar URL directly
                setAvatarUrl(`https:${user.avatar}`);
            } else if (user.avatarUrl) {
                // Use the presigned URL directly
                setAvatarUrl(user.avatarUrl);
            } else {
                // Default avatar if no avatar URL is present
                setAvatarUrl('/path-to-default-avatar.jpg');
            }
        } else {
            // Default avatar if no user
            setAvatarUrl('/path-to-default-avatar.jpg');
        }
    };

    const formatDate = (isoDate) => {
        if (!isoDate) return ''; // Return an empty string if no date is provided
        const date = new Date(isoDate);
        return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
    };

    const handleStatusChange = useCallback((e) => setAbout(e.target.value), []);

    const handleTempEducationChange = useCallback((field, value) => {
        setTempEducation(prev => ({ ...prev, [field]: value }));
    }, []);

    const handleTempCareerChange = useCallback((field, value) => {
        setTempCareer(prev => ({ ...prev, [field]: value }));
    }, []);

    const addEducation = useCallback(() => {
        setEducation(prevEducation => [
            ...prevEducation,
            { school: '', degree: '', fieldOfStudy: '', from: '', to: '', current: false, description: '' }
        ]);
        setExpandedEducationIndex(education.length);
    }, [education.length]);

    const addCareer = useCallback(() => {
        setCareer(prevCareer => [
            ...prevCareer,
            { company: '', position: '', from: '', to: '', current: false, description: '' }
        ]);
        setExpandedCareerIndex(career.length);
    }, [career.length]);

    const toggleExpandEducation = useCallback((index) => {
        setExpandedEducationIndex(expandedEducationIndex === index ? null : index);
        if (expandedEducationIndex !== index) {
            setTempEducation({
                ...education[index],
                from: formatDate(education[index]?.from),
                to: formatDate(education[index]?.to),
            });
        }
    }, [expandedEducationIndex, education]);

    const toggleExpandCareer = useCallback((index) => {
        setExpandedCareerIndex(expandedCareerIndex === index ? null : index);
        if (expandedCareerIndex !== index) {
            setTempCareer({
                ...career[index],
                from: formatDate(career[index]?.from),
                to: formatDate(career[index]?.to),
            });
        }
    }, [expandedCareerIndex, career]);

    const saveEducation = useCallback(() => {
        setEducation(prevEducation => {
            const updatedEducation = [...prevEducation];
            updatedEducation[expandedEducationIndex] = {
                ...tempEducation,
                from: tempEducation.from ? new Date(tempEducation.from).toISOString() : '',
                to: tempEducation.to ? new Date(tempEducation.to).toISOString() : ''
            };
            return updatedEducation;
        });
        setExpandedEducationIndex(null);
    }, [tempEducation, expandedEducationIndex]);

    const saveCareer = useCallback(() => {
        setCareer(prevCareer => {
            const updatedCareer = [...prevCareer];
            updatedCareer[expandedCareerIndex] = {
                ...tempCareer,
                from: tempCareer.from ? new Date(tempCareer.from).toISOString() : '',
                to: tempCareer.to ? new Date(tempCareer.to).toISOString() : ''
            };
            return updatedCareer;
        });
        setExpandedCareerIndex(null);
    }, [tempCareer, expandedCareerIndex]);

    const removeEducation = useCallback((index) => {
        setEducation(prevEducation => prevEducation.filter((_, i) => i !== index));
    }, []);

    const removeCareer = useCallback((index) => {
        setCareer(prevCareer => prevCareer.filter((_, i) => i !== index));
    }, []);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    
    const openModal = () => {
        setIsModalOpen(true);
        setUploadFeedback(''); // Clear any previous feedback
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedFile(null);
    };

    const handleImageUpload = async () => {
        if (!selectedFile) {
            setUploadFeedback('Please select an image file to upload.');
            return;
        }
    
        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);
    
        const formData = new FormData();
        formData.append('image', selectedFile);
    
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        };
    
        try {
            const response = await axios.post('/api/users/image', formData, config);
            console.log('Backend Response:', response); // Log the response
    
            if (response.status === 200) {
                setUploadFeedback('Image updated successfully. This window will close automatically in 3 seconds.');
                // Close the modal after 3 seconds
                setTimeout(() => {
                    closeModal();
                }, 3000);
            } else {
                setUploadFeedback('Failed to update image. ');
            }
        } catch (error) {
            console.error('Error:', error);
            setUploadFeedback('An error occurred during the upload. Please try again.');
        }
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        const profileData = {
            about,
            social,
            education: education.map(edu => ({
                ...edu,
                from: edu.from ? new Date(edu.from).toISOString() : '',
                to: edu.to ? new Date(edu.to).toISOString() : ''
            })),
            career: career.map(car => ({
                ...car,
                from: car.from ? new Date(car.from).toISOString() : '',
                to: car.to ? new Date(car.to).toISOString() : ''
            })),
            location
        };

        console.log(profileData);

        try {
            const response = await axios.post('/api/profile', profileData, config);
            if (response.status === 200 || response.status === 201) {
                setFeedbackMessage('User personal details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update user details.');
            }
        } catch (error) {
            let message = 'An error occurred. Please try again later.';
            if (error.response) {
                if (error.response.status === 400) {
                    setValidationErrors(error.response.data.errors || []);
                } else {
                    message = `Error: ${error.response.status}`;
                }
            }
            setFeedbackMessage(message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">Edit profile</div>
            <div className="ep-updateimg">
            <div className="ep-updateimg-img">
                <img 
                    src={avatarUrl} 
                    alt="user-avatar" 
                    className="ep-avatar-img"
                />
            <div className="ep-avatar-name">{user?.name || 'User Name'}</div>
            </div>
                <div>
                    <button className="ep-updateimg-btn" onClick={openModal}>Change photo</button>

                    {isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <div><FaRegImage className='mc-icon'/></div>
                                <div>
                                    <input type="file" accept="image/*" onChange={handleFileChange} />
                                    <button onClick={handleImageUpload} className="ep-update-btn">Upload</button>
                                    <button onClick={closeModal} className="ep-cancel-btn">
                                    <IoMdCloseCircleOutline />
                                    </button>
                                    {uploadFeedback && <span className="upload-feedback">{uploadFeedback}</span>}
                                </div>
                               
                            </div>
                        </div>
    )}
                </div>
            </div>

            <form className="ep-form" onSubmit={handleSubmit}>

                <div className='ep-dd-header'>
                    <label>Education</label>
                    <button type="button" className="ep-add-btn" onClick={addEducation}>&#x2b;</button>
                </div>

                <div>
                    {education.map((edu, index) => (
                        <div key={index}>
                            <div
                                onClick={() => toggleExpandEducation(index)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={(e) => e.key === 'Enter' && toggleExpandEducation(index)}
                                aria-expanded={expandedEducationIndex === index}
                                aria-controls={`education-${index}`}
                                className="ep-dd-title"
                            >
                                <div>{edu.school || 'Click to add education'}</div>
                                <div>
                                    <button type="button" onClick={() => removeEducation(index)} aria-label={`Remove education entry at index ${index}`} className='ep-rm-btn'><IoMdCloseCircleOutline /></button>
                                    <span>{expandedEducationIndex === index ? '▲' : '▼'}</span>
                                </div>
                            </div>
                            {expandedEducationIndex === index && (
                                <div className='ep-dropdown'>
                                    <div className='ep-dropdown-items'>
                                        <label htmlFor="school">Institution</label>
                                        <input
                                            type="text"
                                            value={tempEducation.school}
                                            onChange={(e) => handleTempEducationChange('school', e.target.value)}
                                            placeholder="School"
                                        />
                                    </div>

                                    <div className='ep-dropdown-items'>
                                        <label htmlFor="degree">Qualification</label>
                                        <input
                                            type="text"
                                            value={tempEducation.degree}
                                            onChange={(e) => handleTempEducationChange('degree', e.target.value)}
                                            placeholder="Degree"
                                        />
                                    </div>

                                    <div className='ep-dropdown-items'>
                                        <label htmlFor="fieldOfStudy">Field of study</label>
                                        <input
                                            type="text"
                                            value={tempEducation.fieldOfStudy}
                                            onChange={(e) => handleTempEducationChange('fieldOfStudy', e.target.value)}
                                            placeholder="Field of study"
                                        />
                                    </div>

                                    <div className='ep-dd-items-time'>
                                        <div className='ep-dropdown-item'>
                                            <label htmlFor="date-from">From</label>
                                            <input
                                                type="date"
                                                value={tempEducation.from}
                                                onChange={(e) => handleTempEducationChange('from', e.target.value)}
                                                placeholder="From"
                                            />
                                        </div>

                                        <div className='ep-dropdown-item'>
                                            <label htmlFor="date-to">To</label>
                                            <input
                                                type="date"
                                                value={tempEducation.to}
                                                onChange={(e) => handleTempEducationChange('to', e.target.value)}
                                                placeholder="To"
                                                disabled={tempEducation.current}
                                            />
                                        </div>

                                    </div>

                                    <div className='ep-dropdown-checkbox'>
                                        <label htmlFor="current">Current</label>
                                        <input
                                            type="checkbox"
                                            checked={tempEducation.current}
                                            onChange={(e) => handleTempEducationChange('current', e.target.checked)}
                                        />
                                    </div>


                                    <textarea
                                        value={tempEducation.description}
                                        onChange={(e) => handleTempEducationChange('description', e.target.value)}
                                        placeholder="Description"
                                        className='ep-textarea'
                                    />
                                    <button type="button" onClick={saveEducation} className='ep-save-btn'>Add to Education</button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className='ep-dd-header'>
                    <label>Career</label>
                    <button type="button" className="ep-add-btn" onClick={addCareer}>&#x2b;</button>
                </div>

                <div>
                    {career.map((car, index) => (
                        <div key={index}>
                            <div
                                onClick={() => toggleExpandCareer(index)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={(e) => e.key === 'Enter' && toggleExpandCareer(index)}
                                aria-expanded={expandedCareerIndex === index}
                                aria-controls={`career-${index}`}
                                className="ep-dd-title"
                            >
                                <div>{car.company || 'Click to add career'}</div>
                                <div>
                                    <button type="button" onClick={() => removeCareer(index)} aria-label={`Remove career entry at index ${index}`} className='ep-rm-btn'><IoMdCloseCircleOutline /></button>
                                    <span>{expandedCareerIndex === index ? '▲' : '▼'}</span>
                                </div>
                            </div>
                            {expandedCareerIndex === index && (
                                <div className='ep-dropdown'>
                                    <div className='ep-dropdown-items'>
                                        <label htmlFor="company">Company</label>
                                        <input
                                            type="text"
                                            value={tempCareer.company}
                                            onChange={(e) => handleTempCareerChange('company', e.target.value)}
                                            placeholder="Company"
                                        />
                                    </div>

                                    <div className='ep-dropdown-items'>
                                        <label htmlFor="position">Position</label>
                                        <input
                                            type="text"
                                            value={tempCareer.position}
                                            onChange={(e) => handleTempCareerChange('position', e.target.value)}
                                            placeholder="Position"
                                        />
                                    </div>



                                    <div className='ep-dd-items-time'>
                                        <div className='ep-dropdown-item'>
                                            <label htmlFor="date-from">From</label>
                                            <input
                                                type="date"
                                                value={tempCareer.from}
                                                onChange={(e) => handleTempCareerChange('from', e.target.value)}
                                                placeholder="From"
                                            />
                                        </div>

                                        <div className='ep-dropdown-item'>
                                            <label htmlFor="date-to">To</label>
                                            <input
                                                type="date"
                                                value={tempCareer.to}
                                                onChange={(e) => handleTempCareerChange('to', e.target.value)}
                                                placeholder="To"
                                                disabled={tempCareer.current}
                                            />
                                        </div>

                                    </div>

                                    <div className='ep-dropdown-checkbox'>
                                        <label htmlFor="current">Current</label>
                                        <input
                                            type="checkbox"
                                            checked={tempCareer.current}
                                            onChange={(e) => handleTempCareerChange('current', e.target.checked)}
                                        />
                                    </div>


                                    <textarea
                                        value={tempCareer.description}
                                        onChange={(e) => handleTempCareerChange('description', e.target.value)}
                                        placeholder="Description"
                                        className='ep-textarea'
                                    />

                                    <button type="button" onClick={saveCareer} className='ep-save-btn'>Add to Career</button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <label htmlFor="location">Location:</label>
                <input type="text" id="location" name="location" value={location} onChange={(e) => setLocation(e.target.value)} placeholder='eg. Silicon Valley, Cal, United States [state, country]'/>

                <label htmlFor="about">About:</label>
                <textarea type="text" id="about" name="about" value={about} onChange={(e) => setAbout(e.target.value)} placeholder='eg - Describe what you do in the simplest terms. Sharing the problems you solve, for whom, and how is a great way to demonstrate your skills, industry knowledge, and work style. For inspiration, ask yourself: Who are you helping when you do your job?' className='ep-about-textarea'/>


                
                <label>Social Media Links:</label>

                <div className='ep-form-group'>

                    <div className="eb-fg-item">
                        <span>
                            <img src="/images/fb-icon.png" alt='fb-logo' />
                        </span>
                        <input
                            type="text"
                            value={social.facebook}
                            onChange={(e) => setSocial(prev => ({ ...prev, facebook: e.target.value }))}
                            placeholder="eg. https://www.facebook.com/dummy-user"
                        />
                    </div>

                    <div className="eb-fg-item">
                        <span>
                            <img src="/images/linkedin-icon.png" alt='linkedin-logo' />
                        </span>
                        <input
                            type="text"
                            value={social.linkedin}
                            onChange={(e) => setSocial(prev => ({ ...prev, linkedin: e.target.value }))}
                            placeholder="eg. https://www.linkedin.com/dummy-user"
                        />
                    </div> 

                    <div className="eb-fg-item">
                        <span>
                            <img src="/images/ig.png" alt='instagram-logo' />
                        </span>
                        <input
                            type="text"
                            value={social.instagram}
                            onChange={(e) => setSocial(prev => ({ ...prev, instagram: e.target.value }))}
                            placeholder="eg. https://www.instagram.com/dummy-user"
                        />
                    </div>

                    <div className="eb-fg-item">
                        <span>
                            <img src="/images/icon-yt.png" alt='youtube-logo' />
                        </span>
                        <input
                            type="text"
                            value={social.youtube}
                            onChange={(e) => setSocial(prev => ({ ...prev, youtube: e.target.value }))}
                            placeholder="eg. https://www.youtube.com/dummy-user"
                        />
                    </div>
    
                </div>
                
                
                <button type="submit" className="ep-save-btn">
                    {isLoading ? 'Saving...' : 'Save Changes'}
                </button>

                {feedbackMessage && <div className="ep-feedback">{feedbackMessage}</div>}
                {validationErrors.length > 0 && (
                    <div className="ep-validation-errors">
                        {validationErrors.map((error, index) => (
                            <div key={index} className="ep-validation-error">{error.msg}</div>
                        ))}
                    </div>
                )}
            </form>
        </div>
    );
};

export default UpdateProfile;


