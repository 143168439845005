import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const RegisterUser = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    nric: '',
    contact: '',
    jobposition: '',
    gender: '',
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    role: 'user', // Default role
  });

  const { email, password, name, nric, contact, jobposition, gender, AddressLine1, AddressLine2, AddressLine3, role } = formData;
  
  const navigate = useNavigate();

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': setAuthToken(localStorage.token)
      }
    };

    const body = JSON.stringify({ email, password, name, nric, contact, jobposition, gender, AddressLine1, AddressLine2, AddressLine3, role });

    try {
      const res = await axios.post('/api/users/register', body, config);

      if (res.status === 200 || res.status === 201) {
        console.log('User registered successfully:', res.data);
        // Redirect to /admin after successful registration
        navigate('/admin');
      } else {
        console.error('Failed to register user:', res.data);
      }
    } catch (error) {
      console.error('Error registering user:', error);
    }
  };


  return (
    <Fragment>
      <main className='roboto-regular'>
        <div className="container">
          <form className="registerCompanyForm roboto-bold" onSubmit={onSubmit}>
            <div className="flex-row-container">
              <fieldset>
                <legend>Register New User</legend>
                <br />
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={email} onChange={onChange} required />

                <label htmlFor="password">Password:</label>
                <input type="password" id="password" name="password" value={password} onChange={onChange} required />

                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" value={name} onChange={onChange} required />

                <label htmlFor="nric">NRIC No:</label>
                <input type="text" id="nric" name="nric" value={nric} onChange={onChange} required />

                <label htmlFor="contact">Contact No:</label>
                <input type="tel" id="contact" name="contact" value={contact} onChange={onChange} required />

              </fieldset>

              <fieldset>
                <legend></legend>
                <br />
                <label htmlFor="jobposition">Job Position:</label>
                <input type="text" id="jobposition" name="jobposition" value={jobposition} onChange={onChange} required />

                <label htmlFor="gender">Sex/Gender:</label>
                <select id="gender" name="gender" value={gender} onChange={onChange} required>
                  <option value="" disabled>Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>

                <label htmlFor="Address">Address:</label>
                <input type="text" id="AddressLine1" name="AddressLine1" placeholder="Address Line 1" value={AddressLine1} onChange={onChange} required />
                <input type="text" id="AddressLine2" name="AddressLine2" placeholder="Address Line 2" value={AddressLine2} onChange={onChange} required />
                <input type="text" id="AddressLine3" name="AddressLine3" placeholder="Address Line 3" value={AddressLine3} onChange={onChange} />

                <label htmlFor="role">Admin Privilege:</label>
                <br />
                <div className="role-container">
                  <input type="radio" id="user" name="role" value="user" checked={role === 'user'} onChange={onChange} required />
                  <label className="role-option" htmlFor="user">User Role</label>

                  <input type="radio" id="admin" name="role" value="admin" checked={role === 'admin'} onChange={onChange} required />
                  <label className="role-option" htmlFor="admin">Admin Role</label>
                </div>
                
              </fieldset>
            </div>

            <button type="submit" className="roboto-bold">Register</button>
          </form>
        </div>
      </main>
    </Fragment>
  );
}

export default RegisterUser;
