import axios from 'axios';
import { setAlert } from './alert';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_PROFILE,
    RESETPASSWORD_SUCCESS,
    RESETPASSWORD_FAIL,
    RESETPASSWORD_REQUEST
} from './types';
import setAuthToken from '../utils/setAuthToken';

// Load User
export const loadUser = () => async dispatch => {
    if(localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get('/api/auth');

        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    } catch(err) {
        console.error('Error loading user:', err); // Log the error
        dispatch({
            type: AUTH_ERROR
        });
    }
};

// Register User (SuperUser/ Admin)
export const register = ({companyName, companyNo, name, email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ companyName, companyNo, name, email, password });

    try {
        const res = await axios.post('/api/users', body, config);

        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        console.error('Error registering user:', err); // Log the error

        if (err.response && err.response.data && err.response.data.errors) {
            const errors = err.response.data.errors;
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: REGISTER_FAIL
        });
    }   
}

// Login User 
export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post('/api/auth', body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(loadUser());
    } catch (err) {
        console.error('Error logging in:', err); // Log the error

        if (err.response && err.response.data && err.response.data.errors) {
            const errors = err.response.data.errors;
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: LOGIN_FAIL
        });
    }   
};

// ResetPassword 
export const resetPassword = (password, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ password });

    try {
        // Send the request to reset the password
        const res = await axios.post(`/api/auth/reset/${token}`, body, config);

        // Dispatch the success action
        dispatch({
            type: RESETPASSWORD_SUCCESS,
            payload: res.data
        });

        dispatch(setAlert('Success', 'danger'));

    } catch (err) {
        console.error('Error:', err); // Log the error for debugging

        // Handle validation or custom errors from the server
        if (err.response && err.response.data && err.response.data.errors) {
            const errors = err.response.data.errors;
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        // Token expiration specific error handling (if necessary)
        if (err.response && err.response.status === 401) {
            dispatch(setAlert('The reset token has expired, please request a new one.', 'danger'));
        }

        // Dispatch failure action
        dispatch({
            type: RESETPASSWORD_FAIL
        });
    }   
};      

// Send Password Reset Link
export const sendResetLink = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });

    try {
        // Dispatch request action to set loading state
        dispatch({ type: RESETPASSWORD_REQUEST });

        // Send the request to initiate the password reset
        await axios.post('/api/auth/reset', body, config);

        // Dispatch success action and show alert
        dispatch({ type: RESETPASSWORD_SUCCESS });
        dispatch(setAlert('An email with a password reset link has been sent to your email', 'green'));

    } catch (err) {
        console.error('Error:', err);

        // Dispatch failure action and show error alert
        dispatch({ type: RESETPASSWORD_FAIL });
        dispatch(setAlert('Error sending password reset email', 'danger'));
    }
};

// Logout / Clear Profile 
export const logout = () => dispatch => {
    dispatch({type: CLEAR_PROFILE});
    dispatch({type: LOGOUT});
};

