import React, { Fragment } from 'react';

const UserRemuneration = () => {

    return (
        <main className="wip-main">
            <img src="/images/spinner.webp" alt="Under Construction" />
            <div className="wip-message">
                <div>We're sorry but this feature is not yet available!</div>
                <br />
                <div>We are working diligently to make this feature available to you soon.</div>
                <br />
                <div>Thank you for your continued patience and understanding.</div>
                <div className="wip-button">
                    <button onClick={() => window.location.href = '/dashboard'}>
                        Return to Dashboard
                    </button>
                </div>
            </div>
        </main>
    );
};


export default UserRemuneration;
