import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

import { MdAddCircleOutline } from "react-icons/md";
import { ImInfo } from "react-icons/im";
import { CiSquareRemove } from "react-icons/ci";

const GeneralSettings = ({ company }) => {
    const [formData, setFormData] = useState({
        leaveIncrements: [],
        leaveCarryOverLimit: 0,
        leaveCarryOverLimitActive: true,
        leaveForfeiturePeriod: 0,
        leaveForfeiturePeriodActive: true,
        maxAccruedLeaves: 0,
        maxAccruedLeavesActive: true,
        forfeitureMethod: 'number',
        forfeitureValue: 0,
        leaveRulesActive: true
    });

    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (company) {
            setFormData({
                leaveIncrements: company.leaveRules?.leaveIncrements || [],
                leaveCarryOverLimit: company.leaveRules?.leaveCarryOverLimit || 0,
                leaveCarryOverLimitActive: company.leaveRules?.leaveCarryOverLimitActive ?? true,
                leaveForfeiturePeriod: company.leaveRules?.leaveForfeiturePeriod || 0,
                leaveForfeiturePeriodActive: company.leaveRules?.leaveForfeiturePeriodActive ?? true,
                maxAccruedLeaves: company.leaveRules?.maxAccruedLeaves || 0,
                maxAccruedLeavesActive: company.leaveRules?.maxAccruedLeavesActive ?? true,
                forfeitureMethod: company.leaveRules?.forfeitureMethod || 'number',
                forfeitureValue: company.leaveRules?.forfeitureValue || 0,
                leaveRulesActive: company.leaveRules?.active ?? true
            });
        }
    }, [company]);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onCheckboxChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.checked });

    const onLeaveIncrementChange = (index, field, value) => {
        const updatedIncrements = [...formData.leaveIncrements];
        if (field === 'active') {
            updatedIncrements[index] = { ...updatedIncrements[index], active: value };
        } else {
            updatedIncrements[index] = { ...updatedIncrements[index], [field]: value };
        }
        setFormData({ ...formData, leaveIncrements: updatedIncrements });
    };

    const addLeaveIncrement = () => {
        setFormData({
            ...formData,
            leaveIncrements: [...formData.leaveIncrements, { yearsInService: '', additionalLeaves: '', active: true }]
        });
    };

    const removeLeaveIncrement = async (index, incrementId, e) => {
    
        // Create an updated array of leave increments excluding the one at the given index
        const updatedIncrements = formData.leaveIncrements.filter((_, i) => i !== index);
        setFormData({ ...formData, leaveIncrements: updatedIncrements });
    
        // Process the form data to match the expected API payload structure
        const processedLeaveFormData = {
            leaveRules: {
                leaveIncrements: updatedIncrements.map(inc => ({
                    ...inc,
                    incrementId: inc._id, // Keep consistent naming
                    yearsInService: Number(inc.yearsInService) || 0,
                    additionalLeaves: Number(inc.additionalLeaves) || 0,
                    active: !!inc.active
                })),
            }
        };
    
        console.log(processedLeaveFormData);
    
        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);
    
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
    
        try {
            // Use the incrementId passed to the function
            const res = await axios.put(`/api/company/leave-increment/${incrementId}`, processedLeaveFormData, config);
    
            console.log('Submitting data:', processedLeaveFormData);
    
            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('Company details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update company details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors);
            } else {
                console.error('Error updating company details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };
    
    

    const handleCompanyDetails = async (e) => {
        e.preventDefault();

        // Process the form data to match the expected API payload structure
        const processedFormData = {
            leaveRules: {
                leaveIncrements: formData.leaveIncrements.map(inc => ({
                    ...inc,
                    yearsInService: Number(inc.yearsInService) || 0,
                    additionalLeaves: Number(inc.additionalLeaves) || 0,
                    active: !!inc.active
                })),
                leaveCarryOverLimit: Number(formData.leaveCarryOverLimit) || 0,
                leaveCarryOverLimitActive: formData.leaveCarryOverLimitActive,
                leaveForfeiturePeriod: Number(formData.leaveForfeiturePeriod) || 0,
                leaveForfeiturePeriodActive: formData.leaveForfeiturePeriodActive,
                maxAccruedLeaves: Number(formData.maxAccruedLeaves) || 0,
                maxAccruedLeavesActive: formData.maxAccruedLeavesActive,
                forfeitureMethod: formData.forfeitureMethod,
                forfeitureValue: Number(formData.forfeitureValue) || 0,
                active: formData.leaveRulesActive // Ensure the `active` field is properly set
            }
        };

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const res = await axios.put('/api/company', processedFormData, config);

            console.log('Submitting data:', processedFormData);

            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('Company details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update company details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors);
            } else {
                console.error('Error updating company details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className='ep-pd roboto-regular'>
            <div className="ep-li-title">General Settings</div>
            <div className="ep-li-desc"></div>

            <form className="ep-form" onSubmit={handleCompanyDetails}>
            <div className="switch">
                        <input
                            type="checkbox"
                            id="leaveRulesActive"
                            name="leaveRulesActive"
                            checked={formData.leaveRulesActive}
                            onChange={onCheckboxChange}
                            className="switch-input"
                        />
                        <label className="switch-label" htmlFor="leaveRulesActive">
                            <span className="switch-button"></span>
                        </label>
                        <label className="switch-message" htmlFor="leaveRulesActive">{formData.leaveRulesActive ? 'Rules Applied' : 'Apply Rules'}</label>
                    </div>
                <div className="leave-rules-section">
                    <div className="leave-yearsinservice-wrap">
                        <div className='gs-item-desc'>
                            <span><ImInfo /></span>
                            <span>Automate the addition of leave to all existing employees after a period of service</span>
                        </div>
                        <button type="button" className="addLIbtn" onClick={addLeaveIncrement}>
                            <span><MdAddCircleOutline /></span><span>Add New Entry</span>
                        </button>

                        {formData.leaveIncrements.map((increment, index) => (
                            <div key={index} className="leave-increment">
                                <div className="leave-increment-active-wrapper">
                                    <input
                                        type="checkbox"
                                        id={`increment-active-${index}`}
                                        name={`increment-active-${index}`}
                                        checked={increment.active}
                                        onChange={(e) => onLeaveIncrementChange(index, 'active', e.target.checked)}
                                    />
                                    <label htmlFor={`increment-active-${index}`}>Active</label>
                                </div>

                                <label htmlFor={`yearsInService-${index}`}>Years in Service:</label>
                                <input
                                    type="number"
                                    id={`yearsInService-${index}`}
                                    name={`yearsInService-${index}`}
                                    value={increment.yearsInService}
                                    onChange={(e) => onLeaveIncrementChange(index, 'yearsInService', e.target.value)}
                                />
                                
                                <label htmlFor={`additionalLeaves-${index}`}>Additional Leaves:</label>
                                <input
                                    type="number"
                                    id={`additionalLeaves-${index}`}
                                    name={`additionalLeaves-${index}`}
                                    value={increment.additionalLeaves}
                                    onChange={(e) => onLeaveIncrementChange(index, 'additionalLeaves', e.target.value)}
                                />

                                {/* Delete button */}
                                <div className="li-btn" onClick={() => removeLeaveIncrement(index, formData.leaveIncrements[index]._id)}><CiSquareRemove /></div>

                            </div>
                        ))}
                    </div>

                    <div className="gs-item-container">
                        <div className='gs-item-desc'>
                            <span><ImInfo /></span>
                            <span>The maximum amount of leave an employee can bring forward to the following year. Calculated at the end of each year on 31st December. <br/><br/> eg. If set to active with input of value {formData.leaveCarryOverLimit}. An employee with a total leave of X amount can only carry forward X amount of leave not more than the inputted {formData.leaveCarryOverLimit}.<br/><br/> X - {formData.leaveCarryOverLimit} = Y (forfeited)   </span>
                        </div>
                        <div className="gs-item-innercontainer">

                            <div className="leave-increment-active-wrapper">
                                <input
                                    type="checkbox"
                                    id="leaveCarryOverLimitActive"
                                    name="leaveCarryOverLimitActive"
                                    checked={formData.leaveCarryOverLimitActive}
                                    onChange={onCheckboxChange}
                                />
                                <label htmlFor="leaveCarryOverLimitActive">Active</label>
                            </div>

                           <div className="gs-item-input-container">
                                <label htmlFor="leaveCarryOverLimit">Maximum Amount of Leave to Carry Over:</label>
                                <input
                                    type="number"
                                    id="leaveCarryOverLimit"
                                    name="leaveCarryOverLimit"
                                    value={formData.leaveCarryOverLimit}
                                    onChange={onChange}
                                />
                           </div>
                        </div>
                    </div>
                    

                    <div className="gs-item-container">
                        <div className='gs-item-desc'>
                            <span><ImInfo /></span>
                            <span>The maximum amount of leave an employee may retain</span>
                        </div>
                        <div className="gs-item-innercontainer">

                            <div className="leave-increment-active-wrapper">
                                <input
                                type="checkbox"
                                id="maxAccruedLeavesActive"
                                name="maxAccruedLeavesActive"
                                checked={formData.maxAccruedLeavesActive}
                                onChange={onCheckboxChange}
                                />
                                <label htmlFor="maxAccruedLeavesActive">Active</label>
                            </div>

                           <div className="gs-item-input-container">
                                <label htmlFor="maxAccruedLeaves">Maximum Accrued Leaves:</label>
                                <input
                                    type="number"
                                    id="maxAccruedLeaves"
                                    name="maxAccruedLeaves"
                                    value={formData.maxAccruedLeaves}
                                    onChange={onChange}
                                />
                           </div>
                        </div>
                    </div>


                    <div className="gs-item-container">
                        <div className='gs-item-desc'>
                            <span><ImInfo /></span>
                            <span>Manage Employee's Leave Usage and Forfeiture of Leaves<br/><br/>Period/Span (months) refers to the cycle of time for the check to be implemented on all employees. If inputted value is {formData.leaveForfeiturePeriod} then the cycle will run every {formData.leaveForfeiturePeriod} months with the initial start date of the cycle beginning on the 1st of January yearly. Acceptable range of inputted value as follows [1, 2, 3, 4]<br/><br/>Forfeiture Method refers to the way the calculation of the amount of leaves to be forfeited is to be counted. Eg. If selection choice: Number then the value inputted in Forfeiture Value of X will be X days of leave to be forfeited.<br/><br />If selection choice: Percentage then the value in Forfeiture Value shall be the percentage of the total Leaves to be forfeited<br/><br/>Eg. If inputted Forfeiture Method is Percentage with a Forfeiture value of 20% and Period/Span of 3, then the check will run every 3 months. Say an employee has 14 days of leave, a Forfeiture value of 20% would equal Employee Leave , 14 x 20% = X (2.8). An employee is required to take 3 days leave every 3 months or any of the remaining 3 leaves shall be forfeited</span>
                        </div>
                        <div className="gs-item-innercontainer">

                            <div className="leave-increment-active-wrapper">
                                <input
                                    type="checkbox"
                                    id="leaveForfeiturePeriodActive"
                                    name="leaveForfeiturePeriodActive"
                                    checked={formData.leaveForfeiturePeriodActive}
                                    onChange={onCheckboxChange}
                                />
                                <label htmlFor="leaveForfeiturePeriodActive">Active</label>
                            </div>

                           <div className="gs-item-input-container">
                           <label htmlFor="leaveForfeiturePeriod">Period/Span (months):</label>
                           <input
                                type="number"
                                id="leaveForfeiturePeriod"
                                name="leaveForfeiturePeriod"
                                value={formData.leaveForfeiturePeriod}
                                onChange={onChange}
                            />
                           </div>

                           <div className="gs-item-input-container">
                           <label htmlFor="forfeitureMethod">Forfeiture Method:</label>
                           <select
                                id="forfeitureMethod"
                                name="forfeitureMethod"
                                value={formData.forfeitureMethod}
                                onChange={onChange}
                            >
                                <option value="number">Number</option>
                                <option value="percentage">Percentage</option>
                            </select>
                           </div>

                           <div className="gs-item-input-container">
                           <label htmlFor="forfeitureValue">Forfeiture Value:</label>
                           <input
                                type="number"
                                id="forfeitureValue"
                                name="forfeitureValue"
                                value={formData.forfeitureValue}
                                onChange={onChange}
                            />
                           </div>


                        </div>
                    </div>

                </div>
                <button type="submit" className='ep-btn'>Update Company Settings</button>
            </form>
            <br/><br/>
            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GeneralSettings;
