import React from 'react'

const Wip = () => {

    return (
        <main className="user-dashboard roboto-regular">
            <div className="wip-container">
                <div>
                    <img src="/images/work-in-progress.png" alt="comingsoon" />
                </div>
                <div className="wip-header">Coming Soon</div>
                <div>We're sorry. This feature is not yet available</div>
                
                <div><a href="https://www.flaticon.com/free-stickers/work-in-progress" title="work in progress stickers" className="attribute">Work in progress stickers created by Stickers - Flaticon</a></div>
                <div></div>
            </div>  
        </main>
    )
}

export default Wip;