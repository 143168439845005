import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsers } from '../../actions/users';
import Spinner from '../layout/Spinner';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { SiMicrosoftexcel } from "react-icons/si";
import { ImFilePdf } from "react-icons/im";


const DEFAULT_AVATAR = '/path-to-default-avatar.jpg'; // Fallback avatar URL

const LeaveSummary = ({ getUsers, auth: { user }, users: { users, loading } }) => {

    useEffect(() => {
        getUsers(); 
    }, [getUsers]);

    // Function to determine the correct avatar URL
    const loadAvatar = (user) => {
        if (user) {
            if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
                // Use the Gravatar URL directly
                return `https:${user.avatar}`;
            } else if (user.avatarUrl) {
                // Use the presigned URL directly
                return user.avatarUrl;
            } else {
                // Default avatar if no avatar URL is present
                return DEFAULT_AVATAR;
            }
        } else {
            // Default avatar if no user
            return DEFAULT_AVATAR;
        }
    };

    // Capitalize words in a string
    const capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const calculateUsedLeave = (initialValue = 0, currentValue = 0) => {
        return Math.max(initialValue - currentValue, 0).toString().slice(0, 3);
    };

    const exportToPDF = () => {
        const tableElement = document.getElementById('leaveSummaryTable');
        if (!tableElement) {
            console.error('Table element not found!');
            return;
        }
    
        const doc = new jsPDF();
        let startY = 20; // Starting Y position
        const margin = { number: 10, text: 20 }; // Separate margins for number and text
        const lineHeight = 10; // Line height for spacing
        const maxWidth = 160; // Max width for wrapping text
        let userIndex = 1; // Counter for numbering
    
        // Get the current date and time
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
        const formattedTime = `${currentDate.getHours()}:${currentDate.getMinutes() < 10 ? '0' : ''}${currentDate.getMinutes()}${currentDate.getMinutes() < 10 ? 'am' : 'pm'}`;
    
        // Add a custom title with date and time
        doc.setFontSize(16);
        doc.text(`Leave Summary Report (${formattedDate}, ${formattedTime})`, margin.number, startY);
        startY += 15;
    
        // Add a separator line below the title
        doc.setLineWidth(0.5);
        doc.line(margin.number, startY, 200, startY);
        startY += 5;
    
        // Add additional spacing before the first number
        startY += 10; // Adds extra space after the line before number 1
    
        // Extract table rows
        const bodyRows = tableElement.querySelectorAll('tbody tr');
    
        bodyRows.forEach(row => {
            const cells = Array.from(row.getElementsByTagName('td'));
    
            if (cells.length > 0) {
                const userName = cells[0]?.innerText || '-';
                const usedLeavesDiv = cells[1]?.querySelector('.table-usedleaves');
                const usedLeaves = usedLeavesDiv
                    ? Array.from(usedLeavesDiv.querySelectorAll('div'))
                          .map(div => {
                              // Extract leave type and value from spans
                              const spans = Array.from(div.querySelectorAll('span'));
                              if (spans.length === 2) {
                                  const type = spans[0]?.innerText.trim();
                                  const value = spans[1]?.innerText.trim();
                                  return `${type}: ${value}`;
                              }
                              return ''; // Fallback for unexpected structures
                          })
                          .filter(entry => entry) // Remove empty entries
                          .join(', ')
                    : 'None';
                const remainingLeaves = Array.from(cells.slice(2)).map((cell, index) => {
                    const leaveType = [
                        'Annual',
                        'Sick',
                        'Maternity',
                        'Hospitalisation',
                        'Replacement',
                        'Compassionate',
                        'Study',
                        'Marriage'
                    ][index];
                    return `${leaveType}: ${cell.innerText || '0'}`;
                }).join(', ');
    
                // Add User Data with Numbering
                doc.setFontSize(12);
                doc.text(`${userIndex}.`, margin.number, startY);
                doc.text(`Name: ${userName}`, margin.text, startY);
                startY += lineHeight;
    
                // Add Used Leaves
                const wrappedUsedLeaves = doc.splitTextToSize(
                    `Used Leaves - ${usedLeaves}`,
                    maxWidth
                );
                wrappedUsedLeaves.forEach(line => {
                    doc.text(line, margin.text, startY);
                    startY += lineHeight;
                });
    
                // Add Remaining Leaves
                const wrappedRemainingLeaves = doc.splitTextToSize(
                    `Remaining Leaves - ${remainingLeaves}`,
                    maxWidth
                );
                wrappedRemainingLeaves.forEach(line => {
                    doc.text(line, margin.text, startY);
                    startY += lineHeight;
                });
    
                startY += 5; // Add extra space after each user
                userIndex++; // Increment user counter
    
                // Add a page break if nearing the bottom of the page
                if (startY > 270) {
                    doc.addPage();
                    startY = 20; // Reset startY for the new page
                }
            }
        });
    
        // Save the PDF
        doc.save('Leave_Summary.pdf');
    };
    

       // Function to export data to Excel with custom styles
       const exportToExcel = () => {
        // Prepare the data for the Excel file
        const leaveColumns = [
            { label: 'Name', key: 'name' },
            { label: 'Annual Used', key: 'annualUsed' },
            { label: 'Sick Used', key: 'sickUsed' },
            { label: 'Maternity Used', key: 'maternityUsed' },
            { label: 'Hospitalisation Used', key: 'hospitalisationUsed' },
            { label: 'Remaining Annual', key: 'remainingAnnual' },
            { label: 'Remaining Sick', key: 'remainingSick' },
            { label: 'Remaining Maternity', key: 'remainingMaternity' },
            { label: 'Remaining Hospitalisation', key: 'remainingHospitalisation' },
        ];
    
        // Capitalize only the first letter of each word in the headers
        const capitalizeFirstLetter = (str) => {
            return str
                .split(' ') // Split by space to handle multiple words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
                .join(' ');
        };
    
        const capitalizedColumns = leaveColumns.map(col => ({
            label: capitalizeFirstLetter(col.label), // Apply capitalization
            key: col.key
        }));
    
        const data = users.map((user) => ({
            name: capitalizeWords(user.name || '-'),
            annualUsed: calculateUsedLeave(user.leave.initialAnnual, user.leave.annual),
            sickUsed: calculateUsedLeave(user.leave.initialMedical, user.leave.medical),
            maternityUsed: calculateUsedLeave(user.leave.initialMaternity, user.leave.maternity),
            hospitalisationUsed: calculateUsedLeave(user.leave.initialhospitalisation, user.leave.hospitalisation),
            remainingAnnual: `${(user.leave.annual || 0).toString().slice(0, 3)}/${user.leave.initialAnnual || 0}`,
            remainingSick: `${(user.leave.medical || 0).toString().slice(0, 3)}/${user.leave.initialMedical || 0}`,
            remainingMaternity: `${(user.leave.maternity || 0).toString().slice(0, 3)}/${user.leave.initialMaternity || 0}`,
            remainingHospitalisation: user.leave.hospitalisation || '0',
        }));
    
        // Create a worksheet from the data
        const ws = XLSX.utils.json_to_sheet(data, { header: capitalizedColumns.map((col) => col.key) });
    
        // Apply styles to the headers: Bold and Capitalize
        const headerStyle = {
            font: { bold: true },
            alignment: { horizontal: 'center', vertical: 'center' },
            fill: { fgColor: { rgb: "4F81BD" } },
            border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        };
    
        // Apply styles to the header cells (first row)
        capitalizedColumns.forEach((col, index) => {
            const cell = XLSX.utils.encode_cell({ r: 0, c: index });
            if (!ws[cell]) ws[cell] = {}; // Ensure cell exists
            ws[cell].v = col.label; // Set the header value (already capitalized)
            ws[cell].s = headerStyle; // Apply the style
        });
    
        // Apply styles to the data rows
        const rowStyle = {
            font: { color: { rgb: "000000" } },
            alignment: { horizontal: 'left', vertical: 'center' },
            border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        };
    
        // Apply styles to each row
        data.forEach((_, rowIndex) => {
            for (let colIndex = 0; colIndex < capitalizedColumns.length; colIndex++) {
                const cell = XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex });
                if (!ws[cell]) ws[cell] = {}; // Ensure cell exists
                ws[cell].s = rowStyle; // Apply the style
            }
        });
    
        // Calculate and set column widths based on content length, with a minimum width of 200px
        const colWidths = capitalizedColumns.map((col, colIndex) => {
            let maxLength = 0;
            data.forEach(row => {
                const cellValue = row[col.key] ? row[col.key].toString() : '';
                maxLength = Math.max(maxLength, cellValue.length);
            });
    
            // The minimum width is set to 200px, calculated in terms of characters
            const minWidth = 200 / 7;  // Approximately 200px = 7 characters in width (rough estimate)
            return { wch: Math.max(minWidth, maxLength + 2) }; // Ensure column width is at least the calculated minimum
        });
    
        // Set column widths in the worksheet
        ws['!cols'] = colWidths;
    
        // Create a new workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Leave Summary');
    
        // Export the Excel file
        XLSX.writeFile(wb, 'Leave_Summary_Styled.xlsx');
    };
    
    
    
    if (loading) {
        return <Spinner />; // Show spinner while loading
    }

    return (
        <Fragment>
            <div className="grid-section-main roboto-regular ls-component-cont">
                <div className="gsm-title">
                    <div className="adm-db-people-title">Leave Summary</div>

                    <div className="ls-table-export">
                        <div onClick={exportToExcel} className="export-exl-button">
                        <SiMicrosoftexcel />
                        </div>

                        <div onClick={exportToPDF} className="export-pdf-btn"><ImFilePdf /></div> 
                        </div>
                    </div>

              
               
                <div className="teams-gsm-content">
                    <div className="table-container">
                        <table id="leaveSummaryTable" className="responsive-table">
                        <thead>
                            {/* Single row for main headers */}
                            <tr>
                            <th className="topemptyheader">Name</th>
                            <th className="topemptyheader">Used Leaves</th>
                            <th colSpan="100%"  className="table-teh">Remaining Leaves</th>
                            </tr>
                            {/* Second row for subheaders */}
                            <tr>
                            <th className="emptyheader1"></th>
                            <th className="emptyheader2"></th>
                            <th>Annual</th>
                            <th>Sick</th>
                            <th>Maternity</th>
                            <th>Hospitalisation</th>
                            <th>Replacement</th>
                            <th>Compassionate</th>
                            <th>Study</th>
                            <th>Marriage</th>
                            </tr>
                        </thead>

                            <tbody>
                                {users && users.length > 0 ? (
                                    users.map((user, index) => (
                                        <tr key={index}>
                                            <td className="ls-header-name">
                                                <span>
                                                    <img
                                                        src={loadAvatar(user)}
                                                        alt={`${user.name || 'User'}'s avatar`}
                                                        className="user-avatar"
                                                    />
                                                </span>
                                                <span>
                                                {capitalizeWords(user.name || '-')}
                                                </span>
                                    
                                            </td>

                                            <td>
                                                <div className="table-usedleaves">
                                                    <div>
                                                        <span>Annual</span>
                                                        <span>{calculateUsedLeave(user.leave.initialAnnual, user.leave.annual)}</span>
                                                    </div>
                                                    <hr />

                                                    <div>
                                                        <span>Sick</span>
                                                        <span>{calculateUsedLeave(user.leave.initialMedical, user.leave.medical)}</span>
                                                    </div>

                                                    <div>
                                                        <span>Maternity</span>
                                                        <span>{calculateUsedLeave(user.leave.initialMaternity, user.leave.maternity)}</span>
                                                    </div>

                                                    <div>
                                                        <span>Hospitalisation</span>
                                                        <span>{calculateUsedLeave(user.leave.initialhospitalisation, user.leave.
                                                        hospitalisation)}</span>
                                                    </div>
                                                </div>
                                            </td>

                                            
                                            <td className='ls-minwidth'>
                                                {(user.leave.annual || 0).toString().slice(0, 3)}/{user.leave.initialAnnual || 0}
                                            </td>

                                            <td className='ls-minwidth'>
                                                {(user.leave.medical || 0).toString().slice(0, 3)}/{user.leave.initialMedical || 0}
                                            </td>

                                            <td className='ls-minwidth'>
                                                {(user.leave.maternity || 0).toString().slice(0, 3)}/{user.leave.initialMaternity || 0}
                                            </td>

                                            <td className='ls-minwidth ls-fontsize'>
                                            {user.leave.hospitalisation || '0'}
                                            </td>

                                            <td className='ls-minwidth ls-fontsize'>
                                            {user.leave.replacement || '0'}
                                            </td>

                                            <td className='ls-minwidth ls-fontsize'>
                                            {user.leave.compassionate || '0'}
                                            </td>

                                            <td className='ls-minwidth ls-fontsize'>
                                            {user.leave.study || '0'}
                                            </td>

                                            <td className='ls-minwidth ls-fontsize'>
                                            {user.leave.marriage || '0'}
                                            </td>
                                          
                                        </tr>
                                    ))
                                    

                                    
                                ) : (
                                    <tr>
                                        <td colSpan="3">No users available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>      

            </div> 
        </Fragment>
    );
};              

LeaveSummary.propTypes = {
    getUsers: PropTypes.func.isRequired,
    auth: PropTypes.shape({
        user: PropTypes.object.isRequired,
    }).isRequired,
    users: PropTypes.shape({
        users: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    users: state.users,
});

export default connect(mapStateToProps, { getUsers })(LeaveSummary);
