import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const UserDetails = ({user}) => {

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        nric: '',
        contact: '',
        jobposition: '',
        gender: '',
        department: '',
        hiredDate: '',
        AddressLine1: '',
        AddressLine2: '',
        AddressLine3: '',
        EmergencyContact: '',
        ecName: '',
        ecRelationship: '',
        role: '',
        leave: {
          annual: 0,
          medical: 0,
          emergency: 0,
          study: 0,
          replacement: 0,
          compassionate: 0,
          marriage: 0,
          maternity: 0,
          hospitalisation: 0,
          unpaid: 0,
        },
        remuneration: {
          bank: '',
          bankAccountNo: '',
          salary: 0,
          epf: 0,
          socso: 0,
        },
      });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (user) {
            setFormData(prevData => ({
                ...prevData,
                name: user.name || '',
                email: user.email || '',
                nric: user.nric || '',
                contact: user.contact || '',
                jobposition: user.jobposition || '',
                gender: user.gender || '',
                department: user.department || '',
                hiredDate: user.hiredDate || '',
                AddressLine1: user.AddressLine1 || '',
                AddressLine2: user.AddressLine2 || '',
                AddressLine3: user.AddressLine3 || '',
                EmergencyContact: user.EmergencyContact || '',
                ecName: user.ecName || '',
                ecRelationship: user.ecRelationship || '',
                role: user.role || '',
                leave: {
                  annual: user.leave?.annual || 0,
                  medical: user.leave?.medical || 0,
                  emergency: user.leave?.emergency || 0,
                  study: user.leave?.study || 0,
                  replacement: user.leave?.replacement || 0,
                  compassionate: user.leave?.compassionate || 0,
                  marriage: user.leave?.marriage || 0,
                  maternity: user.leave?.maternity || 0,
                  hospitalisation: user.leave?.hospitalisation || 0,
                  unpaid: user.leave?.unpaid || 0,
                },
                remuneration: {
                  bank: user.remuneration?.bank || '',
                  bankAccountNo: user.remuneration?.bankAccountNo || '',
                  salary: user.remuneration?.salary || 0,
                  epf: user.remuneration?.epf || 0,
                  socso: user.remuneration?.socso || 0,
                }
            }));
        }
    }, [user]);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handlePersonalDetails = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const res = await axios.put(`/api/users/update/${user._id}`, formData, config);

            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('User personal details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update user details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors);
            } else {
                console.error('Error updating user details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">User Details</div>
            <div className="ep-li-desc">Confidential Information are encrypted on the server to keep data secure and safe.</div>

            <form className="ep-form" onSubmit={handlePersonalDetails}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={onChange} />

                <label htmlFor="nric">Nric No:</label>
                <input type="text" id="nric" name="nric" value={formData.nric} onChange={onChange} />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={onChange} />

                <label htmlFor="gender">Gender:</label>
                    <select id="gender" name="gender" value={formData.gender} onChange={onChange}>
                        <option value="" disabled hidden>Select Gender</option> {/* Default option */}
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>



                <label htmlFor="Address">Address:</label>
                <input type="text" id="AddressLine1" name="AddressLine1" placeholder="Address Line 1" value={formData.AddressLine1} onChange={onChange} required />
                <input type="text" id="AddressLine2" name="AddressLine2" placeholder="Address Line 2" value={formData.AddressLine2} onChange={onChange} required />
                <input type="text" id="AddressLine3" name="AddressLine3" placeholder="Address Line 3" value={formData.AddressLine3} onChange={onChange} />

                <label htmlFor="contact">Contact:</label>
                <input type="number" id="contact" name="contact" value={formData.contact} onChange={onChange} placeholder='Please key in your Contact e.g 012-XXXXXXX' />

                <div className='m-y-divider'></div>

                <div className="ep-li-title">Emergency Contact</div>
                <div className="ep-li-desc">Details on the relevant contact and corresponding relationship in the event of an emergency</div>

                <label htmlFor="ecName">Name:</label>
                <input type="text" id="ecName" name="ecName" value={formData.ecName} onChange={onChange} placeholder='Emergency Contact Name' />

                <label htmlFor="ecRelationship">Relationship:</label>
                <input type="text" id="ecRelationship" name="ecRelationship" value={formData.ecRelationship} onChange={onChange} placeholder='eg Mother, Father, Spouse' />

                <label htmlFor="EmergencyContact">Emergency Contact:</label>
                <input type="number" id="EmergencyContact" name="EmergencyContact" value={formData.EmergencyContact} onChange={onChange} placeholder='Please key in your Emergency Contact e.g 012-XXXXXXX' />

                <div className='m-y-divider'></div>

                <button type="submit" className='ep-btn'>Submit</button>
            </form>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};


export default UserDetails;

