import axios from 'axios';
import {
  CLOCK_IN_SUCCESS,
  CLOCK_IN_FAIL,
  CLOCK_OUT_SUCCESS,
  CLOCK_OUT_FAIL,
  FETCH_CLOCK_STATE_SUCCESS,
  FETCH_CLOCK_STATE_FAIL,
  BREAK_SUCCESS,
  BREAK_FAIL,
  LOADING,
} from './types';

// Utility function to convert Base64 to Blob
const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = atob(base64.split(',')[1]); // Decode base64 (after the comma)
  const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

export const clockIn = (latitude, longitude, reason, imageData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING });

    let res;

    // Check if imageData exists and convert Base64 to Blob/File
    if (imageData) {
      const blob = base64ToBlob(imageData, 'image/png');
      const file = new File([blob], 'attendanceImage.png', { type: 'image/png' });

      const formData = new FormData();
      formData.append('latitude', latitude);
      formData.append('longitude', longitude);
      formData.append('reason', reason);
      formData.append('image', file); // Append the file

      // Send POST request with FormData
      res = await axios.post('/api/auth/clock-in', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set correct content type for file uploads
        },
      });
    } else {
      // If no file, send as JSON
      res = await axios.post('/api/auth/clock-in', { latitude, longitude, reason });
    }

    dispatch({
      type: CLOCK_IN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CLOCK_IN_FAIL,
      payload: err.response?.data?.message || 'Clock in failed',
    });
  }
};

// Clock out action
export const clockOut = (latitude, longitude, clockoutreason, imageData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING });

    let res;

    // Check if imageData exists and convert Base64 to Blob/File
    if (imageData) {
      const blob = base64ToBlob(imageData, 'image/png');
      const file = new File([blob], 'attendanceImage.png', { type: 'image/png' });

      const formData = new FormData();
      formData.append('latitude', latitude);
      formData.append('longitude', longitude);
      formData.append('clockoutreason', clockoutreason); // Use clockoutreason here
      formData.append('image', file); // Append the file

      // Await the Axios request
      res = await axios.post('/api/auth/clock-out', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set correct content type for file uploads
        },
      });
    } else {
      // If no imageData, send as JSON
      res = await axios.post('/api/auth/clock-out', { latitude, longitude, clockoutreason });
    }

    // Dispatch success action after the request
    dispatch({
      type: CLOCK_OUT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CLOCK_OUT_FAIL,
      payload: err.response?.data?.message || 'Clock out failed',
    });
  }
};


// Break action
export const takeBreak = (latitude, longitude) => async (dispatch) => {
  try {
    dispatch({ type: LOADING });
    const res = await axios.post('/api/auth/break', { latitude, longitude });

    dispatch({
      type: BREAK_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BREAK_FAIL,
      payload: err.response?.data?.message || 'Break failed',
    });
  }
};

// Fetch clock-in status
export const fetchClockState = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/auth/clock-state');

    dispatch({
      type: FETCH_CLOCK_STATE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_CLOCK_STATE_FAIL,
      payload: err.response?.data?.message || '',
    });
  }
};
