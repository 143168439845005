import axios from 'axios';
import {
    FETCH_PUBLIC_HOLIDAYS,
    FETCH_PH_ERROR,
    FETCH_PENDING_LEAVES,
    FETCH_LEAVE_RECORDS,
    FETCH_LEAVES_ERROR
} from './types';
import setAuthToken from '../utils/setAuthToken';

// Fetch Public Holidays
export const fetchPublicHolidays = () => async dispatch => {
    try {
        // Set token in headers if available
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const res = await axios.get('/api/leave/publicholidays');
        dispatch({
            type: FETCH_PUBLIC_HOLIDAYS,
            payload: res.data.holidays
        });
    } catch (err) {
        console.error(err);
        dispatch({
            type: FETCH_PH_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

// Fetch Pending Leaves 
export const fetchPendingLeaves = () => async dispatch => {
    try {
        // Set token in headers if available
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const res = await axios.get('/api/leave/admin');
        dispatch({
            type: FETCH_PENDING_LEAVES,
            payload: res.data
        });
    } catch (err) {
        console.error(err);
        dispatch({
            type: FETCH_LEAVES_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

// Fetch Pending Leaves 
export const fetchLeaveRecords = () => async dispatch => {
    try {
        
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const res = await axios.get('/api/leave/record');
        dispatch({
            type: FETCH_LEAVE_RECORDS,
            payload: res.data
        });
    } catch (err) {
        console.error(err);
        dispatch({
            type: FETCH_LEAVES_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};


