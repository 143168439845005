import {
    CLOCK_IN_SUCCESS,
    CLOCK_IN_FAIL,
    CLOCK_OUT_SUCCESS,
    CLOCK_OUT_FAIL,
    FETCH_CLOCK_STATE_SUCCESS,
    FETCH_CLOCK_STATE_FAIL,
    BREAK_SUCCESS,
    BREAK_FAIL,
    LOADING,
  } from '../actions/types';
  
  const initialState = {
    attendance: null,
    loading: false,
    error: null,
    message: null,
  };
  
  const attendanceReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case LOADING:
        return {
          ...state,
          loading: true,
        };
      case CLOCK_IN_SUCCESS:
      case CLOCK_OUT_SUCCESS:
      case BREAK_SUCCESS:
        return {
          ...state,
          attendance: payload,
          loading: false,
          error: null,
          message: payload.message,
        };
      case FETCH_CLOCK_STATE_SUCCESS:
        return {
          ...state,
          attendance: payload,
          loading: false,
          error: null,
        };
      case CLOCK_IN_FAIL:
      case CLOCK_OUT_FAIL:
      case BREAK_FAIL:
      case FETCH_CLOCK_STATE_FAIL:
        return {
          ...state,
          loading: false,
          error: payload,
          message: null,
        };
      default:
        return state;
    }
  };

  export default attendanceReducer;
  