import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchPublicHolidays } from '../../actions/leave';
import setAuthToken from '../../utils/setAuthToken';
import axios from 'axios';
import Spinner from '../layout/Spinner';

const LeaveSetting = ({ fetchPublicHolidays, auth, publicHolidays, loading }) => {
    const [holidays, setHolidays] = useState([]);
    const [newHoliday, setNewHoliday] = useState({ date: '', name: '' });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        fetchPublicHolidays();
    }, [fetchPublicHolidays]);

    useEffect(() => {
        if (publicHolidays.length > 0) {
            const formattedHolidays = publicHolidays.map(holiday => ({
                ...holiday,
                date: holiday.date.split('T')[0]  // Format date to YYYY-MM-DD
            }));
            setHolidays(formattedHolidays);
        }
    }, [publicHolidays]);

    const handleHolidayChange = (index, field, value) => {
        const updatedHolidays = holidays.map((holiday, i) => 
            i === index ? { ...holiday, [field]: value } : holiday
        );
        setHolidays(updatedHolidays);
    };

    const addHoliday = () => {
        if (newHoliday.date && newHoliday.name) {
            const holidayExists = holidays.some(h => 
                new Date(h.date).toISOString().split('T')[0] === new Date(newHoliday.date).toISOString().split('T')[0]
            );
            if (holidayExists) {
                setError('Holiday on this date already exists.');
            } else {
                setHolidays([...holidays, { ...newHoliday }]);
                setNewHoliday({ date: '', name: '' });
                setError('');
            }
        } else {
            setError('Please provide both date and name for the holiday.');
        }
    };

    const removeHoliday = async (id, index) => {
        try {
            setError('');
            setSuccess('');
            setAuthToken(localStorage.getItem('token'));

            await axios.delete(`/api/leave/publicholidays/${id}`);

            setHolidays(holidays.filter((_, i) => i !== index));
            setSuccess('Holiday removed successfully.');
        } catch (error) {
            setError('Error removing holiday.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (holidays.length === 0) {
            setError('Please add at least one holiday.');
            return;
        }

        setAuthToken(localStorage.getItem('token'));

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        const body = JSON.stringify({ holidays });

        try {
            const res = await axios.post('/api/leave/publicholidays', body, config);

            if (res.status === 200 || res.status === 201) {
                setSuccess('Public holidays updated successfully.');
                setError('');
            } else {
                setError('Failed to update public holidays.');
            }
        } catch (error) {
            setError('Error updating public holidays.');
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <Fragment>
            <div className='container'>
                <div className='ph-container roboto-regular'>
                    <form onSubmit={handleSubmit} className='ph-form'>
                        {error && <p style={{ color: 'red' }} className='roboto-bold'>{error}</p>}
                        {success && <p style={{ color: 'green' }} className='roboto-bold'>{success}</p>}
                        
                        <div className="ph-header roboto-bold">Designated Public Holidays</div>
                        <br/>
                        <div className="ph-desc">
                            As per Section 60D(1) of the Employment Act 1955, all employees are entitled to 11 designated public holidays. Additionally, Malaysian employees are entitled to any days appointed as public holidays under Section 8 of the Holidays Act 1951, referred to as "Ad Hoc Public Holidays".
                        </div>
                        <br/>
                        <div className="ph-desc">
                            Kindly insert the selected designated dates to be appointed as public holidays to be integrated into your leave management calendar.
                        </div>
                        <br/><br/>
                        {holidays.map((holiday, index) => (
                        <div key={index}>
                            <input
                                className='ph-input'
                                type="date"
                                value={holiday.date}
                                onChange={(e) => handleHolidayChange(index, 'date', e.target.value)}
                            />
                            <input
                                className='ph-input'
                                type="text"
                                placeholder="Holiday Name"
                                value={holiday.name}
                                onChange={(e) => handleHolidayChange(index, 'name', e.target.value)}
                            />
                            <button type="button" onClick={() => removeHoliday(holiday._id, index)}>Remove</button>
                        </div>
                        ))}
                        <br></br>
                        <div className="ph-maininput-wrapper">
                            <input
                                className='ph-maininput'
                                type="date"
                                value={newHoliday.date}
                                onChange={(e) => setNewHoliday({ ...newHoliday, date: e.target.value })}
                            />
                            <input
                                className='ph-maininput'
                                type="text"
                                placeholder="Holiday Name"
                                value={newHoliday.name}
                                onChange={(e) => setNewHoliday({ ...newHoliday, name: e.target.value })}
                            />
                            <button type="button" className="ph-addholiday-btn roboto-bold" onClick={addHoliday}>Add Holiday</button>
                        </div>
                        <button type="submit" className='submit-btn roboto-bold'>Save Dates</button>
                        <br/>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    publicHolidays: state.leave.publicHolidays,
    loading: state.leave.loading
});

export default connect(mapStateToProps, { fetchPublicHolidays })(LeaveSetting);
