import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { MdOutlineMarkEmailRead, MdOutlinePhoneInTalk } from "react-icons/md";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import { LuUserCircle2 } from "react-icons/lu";
import { RiFolderUserLine } from "react-icons/ri";
import { LiaUserEditSolid } from "react-icons/lia";

const DEFAULT_AVATAR = '/path-to-default-avatar.jpg'; // Move to a constant if used elsewhere

// Define job hierarchy for sorting
const jobHierarchy = {
    "Managing Director": 1,
    "Director": 2,
    "Creative Director": 3,
    "CEO": 4,
    "CFO": 5,
    "Any Director": 6, 
    "Managing Partner": 7,
    "Partner": 8,
    "Any Senior": 9,
    "Any Associate": 10,
    "Any Executive": 11,
};

// Function to get the rank of a job title
const getJobRank = (jobTitle) => {
    if (!jobTitle) return Infinity; // If no title, assign the lowest rank
    const normalizedTitle = jobTitle.trim().toLowerCase(); // Trim spaces and convert to lowercase
    // Iterate through the jobHierarchy to find a matching title
    for (const [title, rank] of Object.entries(jobHierarchy)) {
        if (normalizedTitle === title.toLowerCase()) {
            return rank; 
        }
        if (normalizedTitle.includes("director") && title === "Any Director") {
            return rank; 
        }

         if (normalizedTitle.includes("chief") && title === "Any Chief") {
            return rank; 
        }

         if (normalizedTitle.includes("senior") && title === "Any Senior") {
            return rank; 
        }

        if (normalizedTitle.includes("junior") && title === "Any Junior") {
            return rank; 
        }

        if (normalizedTitle.includes("associate") && title === "Any Associate") {
            return rank;
        }

         if (normalizedTitle.includes("executive") && title === "Any Executive") {
            return rank; 
        }
    }
    return Infinity; // If no match found, return the lowest rank
}; 


const OurPeople = ({ users, admin }) => {
    const [dropdownOpen, setDropdownOpen] = useState(null); // Manage dropdown state for each user

    // Function to determine the correct avatar URL
    const loadAvatar = (user) => {
        if (user) {
            if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
                // Use the Gravatar URL directly
                return `https:${user.avatar}`;
            } else if (user.avatarUrl) {
                // Use the presigned URL directly
                return user.avatarUrl;
            } else {
                // Default avatar if no avatar URL is present
                return DEFAULT_AVATAR;
            }
        } else {
            // Default avatar if no user
            return DEFAULT_AVATAR;
        }
    };

    function capitalizeWords(str) {
        return str
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

    // Function to toggle the dropdown menu
    const toggleDropdown = (userId) => {
        setDropdownOpen(prev => prev === userId ? null : userId);
    };

    // Sort users based on job hierarchy before rendering
    const sortedUsers = [...users].sort((a, b) => getJobRank(a.jobposition) - getJobRank(b.jobposition));

    // Determine if custom styles should be applied
    const containerStyle = sortedUsers.length > 0 && sortedUsers.length < 3 ? { maxWidth: '350px' } : {};

    return (
        <Fragment>
            <div className={`roboto-regular ${sortedUsers.length < 5 ? 'people-grid-section-mini' : 'people-grid-section'}`}>
                {sortedUsers.length > 0 ? (
                    sortedUsers.map(user => (
                        <div
                            key={user._id}
                            className="grid-section-item"
                            style={containerStyle} // Apply styles conditionally
                        >
                            <div className="contact-item-img" style={{borderRadius: '25px'}}>
                                <img src={loadAvatar(user)} alt={user.name} style={{borderRadius: '25px'}} />
                                {/*<div className="gs-status"></div> */}
                            </div>
                            <div className="gs-link-btn" onClick={() => toggleDropdown(user._id)}>
                                <PiDotsThreeCircleLight />
                                {dropdownOpen === user._id && (
                                    <div className="dropdown-menu" id="people-dropdown-menu">
                                        <ul>
                                            <li><Link to={`/user-profile/${user._id}`} className="rm-decor"><LuUserCircle2 /></Link></li>
                                            {admin?.role === 'admin' && (
                                                <Fragment>
                                                    <li><Link to={`/admin/${user._id}`} className="rm-decor"><RiFolderUserLine /></Link></li>
                                                    <li><Link to={`/admin/ed/${user._id}`} className="rm-decor"><LiaUserEditSolid /></Link></li>
                                                </Fragment>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>  
                            <div className="contact-item-particulars">
                                <div className="ci-name">{capitalizeWords(user.name)}</div>
                                <div className='ci-position'>{capitalizeWords(user.jobposition)}</div>
                            </div>
                            <div className="contact-item-details">
                                <div className="ci-work-particulars">
                                    <div>
                                        <span className="ci-wp-title">Department</span>
                                        <span className="ci-wp-desc">{user.department}</span>
                                    </div>
                                    <div>
                                        <span className="ci-wp-title">Hired Date</span>
                                        <span className="ci-wp-desc">
                                            {user.hiredDate ? new Date(user.hiredDate).toLocaleDateString('en-GB') : '-'}
                                        </span>
                                    </div>
                                </div>
                                <div className="ci-email">
                                    <span><MdOutlineMarkEmailRead /></span>
                                    <span>{user.email}</span>
                                </div>
                                <div className="ci-email">
                                    <span><MdOutlinePhoneInTalk /></span>
                                    <span>{user.contact}</span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No users available</div>
                )}
            </div>
        </Fragment>
    );
};

OurPeople.propTypes = {
    users: PropTypes.array.isRequired,
};

export default OurPeople;
