import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchPublicHolidays } from '../../actions/leave';
import Spinner from '../layout/Spinner';
import { Link } from 'react-router-dom';
import HolidayCalendar from '../component/HolidayCalendar'; 

// Helper function to get the day suffix (st, nd, rd, th)
const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return day + 'th';
    switch (day % 10) {
      case 1: return day + 'st';
      case 2: return day + 'nd';
      case 3: return day + 'rd';
      default: return day + 'th';
    }
};

// Helper function to format the date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = getDayWithSuffix(date.getDate());
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    return `${day} ${month} ${year}`;
};

// Helper function to get the day of the week
const getDayFromDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { weekday: 'long' });
};

const PublicHoliday = ({ fetchPublicHolidays, auth: { user }, publicHolidays, loading }) => {
  useEffect(() => {
    fetchPublicHolidays();
  }, [fetchPublicHolidays]);

  // Sort holidays by upcoming dates
  const sortedHolidays = publicHolidays
    ? [...publicHolidays].sort((a, b) => new Date(a.date) - new Date(b.date))
    : [];

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <main className="ph-main roboto-regular">
          <section className="ph-decor">
            <div>
              <img src="/images/ph/phdecor.jpg" alt="public-holidays" />   
            </div>
          </section>

          <section className="ph-main-content">
            <div className="ph-data">
              <h2>Public Holidays</h2>
              {sortedHolidays && sortedHolidays.length > 0 ? (
                <table className="holiday-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Day</th>
                      <th>Holiday</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedHolidays.map((holiday, index) => (
                      <tr key={index}>
                        <td>{formatDate(holiday.date)}</td>
                        <td>{getDayFromDate(holiday.date)}</td>
                        <td>{holiday.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>No Public Holidays Available</div>
              )}
            </div>
            <div className="ph-calendar">
              {user.role !== 'user' && (
                <Link to={`/leave-setting/${user.company}`} className="ph-adminbtn">
                  Manage Holidays
                </Link>
              )}
              <HolidayCalendar />
            </div>
          </section>
        </main>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  publicHolidays: state.leave.publicHolidays,
  loading: state.leave.loading
});

export default connect(mapStateToProps, { fetchPublicHolidays })(PublicHoliday);
