import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentProfile } from '../../actions/profile';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';

// Utility function to get the latest career entry
const getLatestCareerEntry = (careers) => {
  if (!careers || !careers.length) return null; // Add null check for careers array

  return careers
    .sort((a, b) => {
      if (a.current && b.current) {
        return new Date(b.from) - new Date(a.from); // Sort in descending order by 'from' date
      }
      if (a.current) return -1;
      if (b.current) return 1;

      const dateA = a.to ? new Date(a.to) : new Date(0);
      const dateB = b.to ? new Date(b.to) : new Date(0);

      return dateB - dateA;
    })[0];
};

const Profile = ({ getCurrentProfile, auth: { user }, profile: { profile, loading, error } }) => {

  const [activeOption, setActiveOption] = useState('option1');
  const [avatarUrl, setAvatarUrl] = useState('/path-to-default-avatar.jpg');

  // Function to load avatar
  const loadAvatar = () => {
    if (user) {
        if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
            // Use the Gravatar URL directly
            setAvatarUrl(`https:${user.avatar}`);
        } else if (user.avatarUrl) {
            // Use the presigned URL directly
            setAvatarUrl(user.avatarUrl);
        } else {
            // Default avatar if no avatar URL is present
            setAvatarUrl('/path-to-default-avatar.jpg');
        }
    } else {
        // Default avatar if no user
        setAvatarUrl('/path-to-default-avatar.jpg');
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await getCurrentProfile();
      } catch (err) {
        console.log(err)
      }
    };
    fetchProfile();
    loadAvatar();
  }, [getCurrentProfile, loadAvatar]);


  if (loading || profile === null) {
    return <Spinner />;
  }

    // Handler to set active option
    const handleOptionClick = (option) => {
      setActiveOption(option);
    };

     // Function to determine the button style
    const getButtonStyle = (option) => {
      return {
        backgroundColor: activeOption === option ? 'rgb(52, 89, 154)' : 'transparent',
        color: activeOption === option ? 'white' : 'black',
      };
    };  
    
    const latestCareer = getLatestCareerEntry(profile.career);
    console.log(latestCareer);
            
  return (
    <Fragment>
      {user && profile ? (
        <main className='roboto-regular pfp-main'>
        <div className="pfp-hero">

          <div className="hero-img">
            <img src='/images/indexhero.jpg' alt='company-logo' />
          </div>

          <div className="userpfp-img">
            <img src={avatarUrl} alt='user-img' />
          </div>

          <div className="userpfp-sm-links">
            <div className="sm-links">
              {profile.social.facebook && (
                <a href={profile.social.facebook} className="sm-links-img" target="_blank" rel="noopener noreferrer">
                  <img src="/images/fb-icon.png" alt="facebook" />
                </a>
              )}

              {profile.social.linkedin && (
                <a href={profile.social.linkedin} className="sm-links-img" target="_blank" rel="noopener noreferrer">
                  <img src="/images/linkedin-icon.png" alt="linkedin" />
                </a>
              )}

              {profile.social.youtube && (
                <a href={profile.social.youtube} className="sm-links-img" target="_blank" rel="noopener noreferrer">
                  <img src="/images/icon-yt.png" alt="youtube" />
                </a>
              )}

              {profile.social.instagram && (
                <a href={profile.social.instagram} className="sm-links-img" target="_blank" rel="noopener noreferrer">
                  <img src="/images/ig.png" alt="instagram" />
                </a>
              )}
            </div>
          </div>


          <div className="userpfp-pd">
            <div className="userpfp-pd-user">
              <div className="userpfp-name">{profile.user.name}</div>
              <div className="userpfp-role">{profile.user.jobposition}</div>
              <div className="userpfp-innercont">
                <span className="userpfp-location">{profile.location}</span>
                <span className="userpfp-link">&#x27A4; &nbsp; Contact info</span>
              </div>

              {/* 
              <div className="userpfp-connect-innercont">
                <div className="userpfp-imgconnect-innercont">
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                  <div className="userpfp-connect-userimg">
                    <img src="/images/img.png" alt='connections-userimg' />
                  </div>
                </div>
                <div className='userpfp-location'>8,000 connections</div>
              </div>

              */}
              
              {user.id !== profile.user.id && (
                <div className="userpfp-innercont-btn">
                  <div className="userpfp-action-btn">&#x2b; Connect</div>
                  <div>Message</div>
                </div>
              )}

            </div>
            <div className="userpfp-pd-compedu">
              <div className="userpfp-compedu-cont">
                <div className="userpfp-compedu-img">
                  <img src="/images/acr-logo.png" alt="company-logo" />
                </div>
                <div className="userpfp-compedu-name"> {latestCareer ? latestCareer.company : ''} </div>
              </div>

              <div className="userpfp-compedu-cont">
                <div className="userpfp-compedu-img">
                  <img src="/images/uol-logo.jpg" alt="company-logo" />
                </div>
                <div className="userpfp-compedu-name">University of London </div>
              </div>

            </div>
          </div>   

        </div>
        
        <div className="userpfp-main">

          <div className="userpfp-act-links">
            <div onClick={() => handleOptionClick('option1')}className="userpfp-act-links-btn" style={getButtonStyle('option1')}>About Me</div>
            <div onClick={() => handleOptionClick('option2')} className="userpfp-act-links-btn" style={getButtonStyle('option2')}>Portfolio</div>
          </div>

          <div className="userpfp-links-main">

               {activeOption === 'option1' && (
                <div>
                  {user.id === profile.user.id && profile.about === '' && (
                    <div className="userpfp-innercont-btn">
                      <div className="userpfp-action-btn">&#x2b; Connect</div>
                      <div>Message</div>
                    </div>
                  )}
                    <div className='userpfp-about1'>{profile.about}</div>
                </div>
                )}

                {activeOption === 'option2' && (
                  <div>
                    <h2>No Portfolio</h2>
                    <br/>
                    <p>Portfolio will be automatically added on completion of existing projects and work task</p>
                  </div>
                )}

            </div>

        </div>

      </main>
      ) : (<div><Spinner /></div>)}
    </Fragment>
  );              
};

Profile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  profile: PropTypes.shape({
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {getCurrentProfile})(Profile);
