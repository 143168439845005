import React, { Fragment, useEffect, useState } from 'react';
import { connect, useSelector, useDispatch  } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentProfile } from '../../actions/profile';
import { fetchClockState } from '../../actions/attendance';
import Spinner from '../layout/Spinner';
import { Link } from 'react-router-dom';

import Calendar from '../component/Calendar.js';
import Navigation from '../component/Navigation.js';
import Team from '../component/Team.js';
import LeaveDashboard from '../component/LeaveDashboard.js';
import Attendance from '../component/Attendance.js';

import { LiaHeart } from "react-icons/lia";
import { BsArrowUpRightCircle } from "react-icons/bs";

const Dashboard = ({ getCurrentProfile, auth: { user }, profile: { profile, loading } }) => {
  const [fetchError, setFetchError] = useState('');
  const [greeting, setGreeting] = useState('');
  const dispatch = useDispatch();

  // Get attendance state from Redux store
  const { attendance, loading: attendanceLoading, error: attendanceError } = useSelector((state) => state.attendance);

  useEffect(() => {
    dispatch(fetchClockState()); // Ensure we have the latest attendance state on mount
  }, [dispatch, attendance]);


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await getCurrentProfile();
      } catch (err) {
        setFetchError('Failed to fetch profile');
      }
    };
    fetchProfile();
  }, [getCurrentProfile]);

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting('Morning');
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting('Afternoon');
    } else {
      setGreeting('Take a break and get off work!');
    }
  }, []);

  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  if (loading && profile === null) {
    return <Spinner />;
  }

  if (fetchError) {
    return <div>Error: {fetchError}</div>;
  }

  // Check if user object exists
  if (!user) {
    return <Spinner />;
  }

  // Check if profile exists
  if (!profile) {
    return (
      <Fragment>
        <div className="alert alert-warning">
          Please complete your profile <Link to="/edit-profile">here</Link>.
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <main className="user-dashboard roboto-regular">
        <div className="welcome-user">
          <span>{greeting} {capitalizeWords(user.name)}</span>
          <span><LiaHeart /></span>
        </div>
        <div className="ud-grid">
          <Navigation user={user}/>
          <LeaveDashboard user={user} />
          <Calendar />

          <div className="grid-section-main roboto-regular">
                <div className="gsm-title" >
                    <div className="adm-db-people-title">Attendance</div>
                    <div><Link to="/attendance" className="icon-reset"><BsArrowUpRightCircle /></Link></div>
                </div>

                <div className="teams-gsm-content" id="gsm-att">
                   <Attendance />
                </div>
            </div>

          <Team />

        </div>
      </main>
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  profile: PropTypes.shape({
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);
