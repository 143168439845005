import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from '../../actions/users';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';

const ManageUser = ({ getUser, auth, user: {user, loading} }) => {
    const { id } = useParams(); // Use the useParams hook to access the route parameter

    // Function to determine the correct avatar URL
    const loadAvatar = (user) => {
        if (user) {
        if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
            // Use the Gravatar URL directly
            return `https:${user.avatar}`;
        } else if (user.avatarUrl) {
            // Use the presigned URL directly
            return user.avatarUrl;
        } else {
            // Default avatar if no avatar URL is present
            return '/path-to-default-avatar.jpg';
        }
        } else {
        // Default avatar if no user
        return '/path-to-default-avatar.jpg';
        }
    };

    useEffect(() => {
        getUser(id); // Pass the user ID to the getUser action
        loadAvatar();
    }, [getUser, id]);

    if (loading) {
        return <Spinner />;
      }

    return (
        <Fragment>
            {user ? (
                <main className="roboto-regular main-top">
                    <div className="usermng-pfp">
                        <div className="usermng-title">Employee's Profile</div>
                        <div className="usermng-user-particulars-container">
                            <div className="usermng-avatar-cont">
                                <img src={loadAvatar(user)} alt="user-avatar" />
                            </div>
                            <div className="usermng-particular">
                                <div>
                                    <div>{user.name}</div>
                                    <div>{user.jobposition}</div>
                                </div>  
                                <div>
                                    <div>Email: {user.email}</div>
                                    <div>Contact No: {user.contact}</div>
                                </div>
                            </div>
                        </div>

                       <div className="usermng-actions">
                        <Link to={`/admin/leave/${user._id}`} className='action-item'>
                            <img src="/images/paper-32.ico" alt="Leave-Management" />
                            <div>Leave Management</div>
                        </Link>
                        <Link to="/comingsoon"  className='action-item'>
                            <img src="/images/dollar-3-32.ico" alt="Leave-Management" />
                            <div>Remuneration</div>
                        </Link>
                        <Link to={`/admin/ed/${user._id}`}  className='action-item'>
                            <img src="/images/edit-9-32.ico" alt="Leave-Management" />
                            <div>Employment Details</div>
                        </Link>
                       </div>
                    </div>
                </main>
            ) : (
                <main>
                    <Spinner />
                </main>
            )}
        </Fragment>
    );
};

ManageUser.propTypes = {
    getUser: PropTypes.func.isRequired,
    auth: PropTypes.shape({
        user: PropTypes.shape({
            _id: PropTypes.string
        })
    }).isRequired,
    user: PropTypes.shape({
        user: PropTypes.object,
        loading: PropTypes.bool.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    user: state.users, 
});

export default connect(mapStateToProps, { getUser })(ManageUser);
