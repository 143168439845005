import {
    GET_USERS,
    GET_USER,
    USERS_ERROR
} from '../actions/types';

const initialState = {
    user: null,
    users: [],
    loading: true,
    error: {}
}

const usersReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
      case GET_USER:
        return {
            ...state,
            user: payload,
            loading: false
        };
      case GET_USERS:
        return {
            ...state,
            users: payload,
            loading: false
        };
      case USERS_ERROR:
        return {
            ...state,
            error: payload,
            loading: false
        };
      default:
        return state;
    }
  };
  
  export default usersReducer;

  

  