import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const LeaveEntitlements = ({ user }) => {
    const [formData, setFormData] = useState({
        leave: {
            initialAnnual: '',
            initialMedical: '',
            initialMaternity: '',
            initialhospitalisation: ''
        }
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (user) {
            setFormData({
                leave: {
                    initialAnnual: user.leave?.initialAnnual || '',
                    initialMedical: user.leave?.initialMedical || '',
                    initialMaternity: user.leave?.initialMaternity || '',
                    initialhospitalisation: user.leave?.initialhospitalisation || ''
                }
            });
        }
    }, [user]);

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            leave: {
                ...prevFormData.leave,
                [name]: value
            }
        }));
    };

    const handlePersonalDetails = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const res = await axios.put(`/api/users/update/${user._id}`, formData, config);

            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('User personal details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update user details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors || []);
            } else {
                console.error('Error updating user details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">Leave Entitlements</div>
            <div className="ep-li-desc">The Entitled Leaves as per the Employment Contract</div>

            <form className="ep-form" onSubmit={handlePersonalDetails}>
                <label htmlFor="initialAnnual">Entitled Total Annual Leaves:</label>
                <input
                    type="number"
                    name="initialAnnual"
                    value={formData.leave.initialAnnual}
                    onChange={onChange}
                    className='roboto-bold'
                />

                <label htmlFor="initialMedical">Entitled Total Sick Leaves:</label>
                <input
                    type="number"
                    name="initialMedical"
                    value={formData.leave.initialMedical}
                    onChange={onChange}
                    className='roboto-bold'
                />

                <label htmlFor="initialMaternity">Entitled Total Maternity Leaves:</label>
                <input
                    type="number"
                    name="initialMaternity"
                    value={formData.leave.initialMaternity}
                    onChange={onChange}
                    className='roboto-bold'
                />

                <label htmlFor="initialhospitalisation">Entitled Hospitalisation Leaves:</label>
                <input
                    type="number"
                    name="initialhospitalisation"
                    value={formData.leave.initialhospitalisation}
                    onChange={onChange}
                    className='roboto-bold'
                />

                <div className='m-y-divider'></div>

                <button type="submit" className='ep-btn'>Submit</button>
            </form>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LeaveEntitlements;
