import React from 'react'
import { Link } from 'react-router-dom';

import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineManageHistory } from "react-icons/md";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";

const Hero = () => {

    return (
        <div className='lp-hero-container'>
            <div className='lp-hero-inner-cont'>
                <div className='lp-hero-header'>
                    <div className='hero-animation'>
                        <img src='/images/krafsync-animate.png' alt='krafsync' className='diagonal-bounce-animation'/>
                    </div>
                    <div className='lp-hh-title'>
                        <div>Crafted for productivity,</div>
                        <div>built for passion</div>
                        <div>
                            <div>Your all-in-one platform to streamline every facet of your work.</div>

                            <Link to='/Register' className='lp-nav-register lp-nav-btns'>
                            <span>Get Started</span>
                            <span className='lp-nav-register-arw'><FaArrowRightLong /></span>
                            </Link>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div className='lp-hero-inner-cont'>
                <div className='lp-hero-grid'>
                    <div className='lp-grid-item'>
                        <div className='lp-gi-box'>
                            <MdOutlineRocketLaunch />
                        </div>
                    </div>
                    <div className='lp-grid-item'>
                        <div className='lp-gi-box'>
                            <MdOutlineManageHistory />
                        </div>
                    </div>
                    <div className='lp-grid-item'>
                        <div className='lp-gi-box'>
                            <FaPeopleGroup />
                        </div>
                    </div>
                    <div className='lp-grid-item'>
                        <div className='lp-gi-box'>
                            <HiOutlineClipboardDocumentList />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    

    )
}

export default Hero;