import axios from 'axios';
import { setAlert } from './alert';

import {
    GET_USERS,
    GET_USER,
    USERS_ERROR
} from './types';

//GET ALL USERS
export const getUsers = () => async dispatch => {
    try {
        const res= await axios.get('/api/users');

        dispatch({
            type: GET_USERS,
            payload: res.data
        });
    } catch(err) {
        dispatch({
            type: USERS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }           
}

//GET SINGLE USER BY ID PARAM
export const getUser = (id) => async dispatch => {
    try {
        const res= await axios.get(`/api/users/admin/${id}`);

        dispatch({
            type: GET_USER,
            payload: res.data
        });
    } catch(err) {
        dispatch({
            type: USERS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }           
}
