import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import Attendance from '../component/Attendance';
import { fetchClockState } from '../../actions/attendance'; 
import { loadUser } from '../../actions/auth'; 
import Spinner from '../layout/Spinner';

import { IoFastFoodOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";

const AttendanceDashboard = () => {
  const dispatch = useDispatch(); 
  const attendance = useSelector((state) => state.attendance); 
  const auth = useSelector((state) => state.auth); 
  const [avatarUrl, setAvatarUrl] = useState('/path-to-default-avatar.jpg');

  useEffect(() => {
    dispatch(fetchClockState());
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    const user = auth?.user;
    if (user) {
      if (user.avatar?.startsWith('//www.gravatar.com/avatar/')) {
        setAvatarUrl(`https:${user.avatar}`);
      } else if (user.avatarUrl) {
        setAvatarUrl(user.avatarUrl);
      } else {
        setAvatarUrl('/path-to-default-avatar.jpg');
      }
    }
  }, [auth?.user]);

  const isLoading = attendance.loading || auth.loading;

  if (isLoading) {
    return <Spinner />; // Show spinner while loading
  }

  // Sort records by date (newest to oldest) and limit to the first 5
  const allAttendanceRecords = (attendance.attendance?.allAttendanceRecords || [])
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 5);

    const convertDecimalHoursToHMS = (decimalHours) => {
      const totalSeconds = Math.round(decimalHours * 3600); // Convert to total seconds
      const hours = Math.floor(totalSeconds / 3600); // Get whole hours
      const minutes = Math.floor((totalSeconds % 3600) / 60); // Get remaining minutes
      const seconds = totalSeconds % 60; // Get remaining seconds
    
      // Construct the output array
      const parts = [];
      if (hours > 0) {
        parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
      }
      if (minutes > 0) {
        parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
      }
      if (seconds > 0) {
        parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
      }
    
      // Join the parts with a space
      return parts.length > 0 ? parts.join(' ') : '0 seconds'; // Return "0 seconds" if all are zero
    };

    function formatTime(date) {
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    }    

  return (
    <Fragment>
      {auth && attendance ? (
        <main className="attendance-dashboard roboto-regular">
          <div className="ad-left">
            <div className='adl-component'>
              <Attendance />
            </div>
          </div>

          <div className="ad-right">
            {allAttendanceRecords.map((attendanceRecord) => (
              <div key={attendanceRecord._id} className='ar-item-cont'> 
              <div className='ar-time'>
              {new Date(attendanceRecord.date).toLocaleDateString('en-GB', {
                weekday: 'long', // Adds the day of the week
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              }).replace(/(\w+)(\s)/, '$1, $2')} {/* Adds a comma after the weekday */}
            </div>



                <div>
                  {attendanceRecord.records.length > 0 && (
                    <div>
                      {/* First clock in and clock out */}
                      <div key={attendanceRecord.records[0]._id || 0} className='ar-items'>
                        <div><span className='ar-items-header'>Clock in</span> {attendanceRecord.records[0].checkInTime ? formatTime(attendanceRecord.records[0].checkInTime) : ''}</div>
                        <div><span className='ar-items-header'>Clock out</span> {attendanceRecord.records[0].checkOutTime ? formatTime(attendanceRecord.records[0].checkOutTime) : 'Ongoing'}</div>
                      </div>

                      {/* Break times, if available */}
                      {attendanceRecord.startBreakTime && attendanceRecord.endBreakTime && (
                        <div>
                        <div className='ar-items'>
                          <div><span className='ar-items-header'>Break</span> {formatTime(attendanceRecord.startBreakTime)}</div>
                          <div><span className='ar-items-header'>Break End</span> {formatTime(attendanceRecord.endBreakTime)}</div>
                          
                        </div>
                        <div className='break-duration'>
                          <span><IoFastFoodOutline /></span>
                          <span>{convertDecimalHoursToHMS(attendanceRecord.breakDuration)}</span>
                        </div>
                        </div>    
                      )}

                      {/* Subsequent clock-ins and clock-outs after the break */}
                      {attendanceRecord.records.slice(1).map((record, index) => (
                        <div key={record._id || index + 1} className='ar-items'>
                          <div><span className='ar-items-header'>Clock in</span> {record.checkInTime ? formatTime(record.checkInTime) : ''}</div>
                          <div><span className='ar-items-header'>Clock out</span> {record.checkOutTime ? formatTime(record.checkOutTime) : 'Ongoing'}</div>
                        </div>
                      ))}

                      <div className="ar-td">
                        <div>Total Work Hours</div>
                        <div>
                          <span><IoMdTime /></span>
                          <span>{convertDecimalHoursToHMS(attendanceRecord.duration)}</span>
                        </div>
                      
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </main>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default AttendanceDashboard;


