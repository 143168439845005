import React, { Fragment, useEffect, useState, useRef } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Underline } from '@tiptap/extension-underline';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import MenuBar from './MenuBar';
import ImageResize from 'tiptap-extension-resize-image';
import { TextAlign } from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import DragHandle from '@tiptap-pro/extension-drag-handle-react';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import { ColumnExtension } from "@gocapsule/column-extension";
import axios from 'axios'; 

import { LuBold, LuItalic } from "react-icons/lu";
import { TfiUnderline } from "react-icons/tfi";
import { FaStrikethrough } from "react-icons/fa6";
import { PiHighlighterBold } from "react-icons/pi";

const TextEditor = ({ documentId }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      ImageResize,
      TextAlign.configure({ types: ['paragraph', 'heading'] }),
      Highlight,
      TaskList,
      TaskItem,
      BubbleMenu.configure({
        element: document.querySelector('.bubble-menu'),
      }),
      TextStyle,
      Color,
      ColumnExtension,
    ],
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
    },
  });
  

  const bubbleMenuRef = useRef(null);
  const [isBubbleMenuVisible, setIsBubbleMenuVisible] = useState(false);

  // Update the bubble menu visibility and position
  useEffect(() => {
    if (!editor || !bubbleMenuRef.current) return;
  
    const updateBubbleMenu = () => {
      const { selection } = editor.state;
      const { from, to } = selection;
  
      // Hide menu for empty selection
      if (from === to) {
        setIsBubbleMenuVisible(false);
        bubbleMenuRef.current.style.display = 'none';
        return;
      }
  
      // Check if selection is inside a table
      const $from = editor.state.selection.$from;
      let insideTable = false;
  
      for (let depth = $from.depth; depth > 0; depth--) {
        const parentNode = $from.node(depth);
        if (['table', 'table_row', 'table_cell', 'table_header'].includes(parentNode.type.name)) {
          insideTable = true;
          break;
        }
      }
  
      // Hide menu if inside a table or node type is image
      const node = editor.state.doc.nodeAt(from);
      if (insideTable || node?.type.name === 'image') {
        setIsBubbleMenuVisible(false);
        bubbleMenuRef.current.style.display = 'none';
        return;
      }
  
      // Show menu and position it
      setIsBubbleMenuVisible(true);
      const coords = editor.view.coordsAtPos(from);
      if (coords) {
        bubbleMenuRef.current.style.position = 'absolute';
        bubbleMenuRef.current.style.top = `${coords.top + window.scrollY - bubbleMenuRef.current.offsetHeight - 10}px`;
        bubbleMenuRef.current.style.left = `${coords.left + window.scrollX}px`;
        bubbleMenuRef.current.style.display = 'flex';
      }
    };
  
    editor.on('selectionUpdate', updateBubbleMenu);
  
    return () => {
      editor.off('selectionUpdate', updateBubbleMenu);
      if (bubbleMenuRef.current) {
        bubbleMenuRef.current.style.display = 'none';
      }
    };
  }, [editor]);
  


// Load document content when component mounts
useEffect(() => {
  const loadDocument = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/documents/${documentId}`);
      if (response.data && response.data.content) {
        editor.commands.setContent(response.data);
      }
    } catch (error) {
      console.error('Failed to load document:', error.message);
    }
  };

  if (documentId) loadDocument();
}, [documentId, editor]);

// Save document
const handleSave = async () => {
  try {
    const content = editor.getJSON(); 
    const title = "My Document"; // Example title, adjust as needed

    const response = await axios.post('http://localhost:5000/api/documents', {
      id: documentId, 
      title,
      content,
    });

    if (response.data) {
      alert('Document saved successfully!');
    }
  } catch (error) {
    console.error('Failed to save document:', error.message);
  }
};


  if (!editor) {
    return <div>Loading...</div>;
  }
  

  return (
    <Fragment>
      <MenuBar editor={editor} />

      {/* Bubble Menu */}
      <div
        ref={bubbleMenuRef}
        className="bubble-menu"
        style={{
          display: isBubbleMenuVisible ? 'flex' : 'none',
          transition: 'top 0.1s, left 0.1s', // Smooth position change
        }}
      >
        <div>
            {/* Color Picker Input */}
            <input
            type="color"
            onInput={event => editor.chain().focus().setColor(event.target.value).run()}
            value={editor.getAttributes('textStyle').color || "#000000"} 
            data-testid="setCustomColor"
            className="color-picker"
          />

        </div>
         <div onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'is-active' : ''}>
            <LuBold />
          </div>
          <div onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'is-active' : ''}>
            <LuItalic />
          </div>
          <div onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive('underline') ? 'is-active' : ''}>
            <TfiUnderline />
          </div>
          <div onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive('strike') ? 'is-active' : ''}>
            <FaStrikethrough />
          </div>
          <div onClick={() => editor.chain().focus().toggleHighlight().run()} className={editor.isActive('highlight') ? 'is-active' && 'highlightis-active' : ''}>
            <PiHighlighterBold />
          </div>
      </div>

      <button onClick={handleSave} style={{ marginTop: '10px' }}>
        Save Document
      </button>

      <div>
        <DragHandle editor={editor} />
        <EditorContent editor={editor} />
      </div>
    </Fragment> 
  );
};

export default TextEditor; 



