import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'react-tooltip/dist/react-tooltip.css';

const HolidayCalendar = () => {

  return (
    <div className="ph-calendar-container">
      <div className="ph-calendar-wrapper">
        <div className="ph-calendar">
          <Calendar />
        </div>
      </div>
    </div>
  );
};

export default HolidayCalendar;
