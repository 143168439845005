import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const EmploymentDetails = ({ user }) => {
    const [formData, setFormData] = useState({
        jobposition: '',
        department: '',
        hiredDate: ''
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (user) {
            setFormData({
                jobposition: user.jobposition || '',
                department: user.department || '',
                hiredDate: user.hiredDate ? new Date(user.hiredDate).toISOString().substring(0, 10) : ''
            });
        }
    }, [user]);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handlePersonalDetails = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const res = await axios.put(`/api/users/update/${user._id}`, formData, config);

            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('User personal details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update user details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors || []);
            } else {
                console.error('Error updating user details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">Employment Details</div>
            <div className="ep-li-desc">Confidential Information is encrypted on the server to keep data secure and safe.</div>

            <form className="ep-form" onSubmit={handlePersonalDetails}>
                <label htmlFor="jobposition">Position:</label>
                <input type="text" id="jobposition" name="jobposition" value={formData.jobposition} onChange={onChange} />

                <label htmlFor="department">Department:</label>
                <input type="text" id="department" name="department" value={formData.department} onChange={onChange} />

                <label htmlFor="hiredDate">Hired Date:</label>
                <input 
                    type="date" 
                    id="hiredDate" 
                    name="hiredDate" 
                    value={formData.hiredDate} 
                    onChange={onChange} 
                />

                <div className='m-y-divider'></div>

                <button type="submit" className='ep-btn'>Submit</button>
            </form>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default EmploymentDetails;
