import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { clockOut } from '../../actions/attendance';

import { FaCamera } from "react-icons/fa";
import { MdCameraswitch } from "react-icons/md";
import { RiCameraLensFill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { FaArrowRotateRight } from "react-icons/fa6";

const AttendanceModalClockOut = ({ isOp, onCl, errorMessage, onSuccessfulSubmit }) => {
  const [clockoutreason, setClockOutReason] = useState('');
  const [localError, setLocalError] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hasCamera, setHasCamera] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [cameraActive, setCameraActive] = useState(false); // Track camera status
  const [currentCamera, setCurrentCamera] = useState(null); // Track current camera
  const [videoDevices, setVideoDevices] = useState([]); // Store available video devices
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  // Effect to update local error state when errorMessage prop changes
  useEffect(() => {
    if (errorMessage) {
      setLocalError(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      checkForCameras();
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const checkForCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      setVideoDevices(videoDevices);
      setHasCamera(videoDevices.length > 0);
      if (videoDevices.length > 0) {
        setCurrentCamera(videoDevices[0].deviceId); // Set default camera
      }
    } catch (error) {
      console.error('Error checking for camera:', error);
      setHasCamera(false);
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: currentCamera ? { exact: currentCamera } : undefined }
      });
      videoRef.current.srcObject = stream;
      setCameraActive(true);
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const handleOpenCamera = () => {
    if (!cameraActive) {
      startCamera();
    }
  };

  const takePicture = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');

      // Draw the video frame onto the canvas
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

      // Get image data from the canvas
      const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
      const data = imageData.data;

      // Calculate the average brightness
      let totalBrightness = 0;
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        totalBrightness += (r * 299 + g * 587 + b * 114) / 1000;
      }

      const avgBrightness = totalBrightness / (data.length / 4);
      const textColor = avgBrightness > 128 ? 'black' : 'white';

      const now = new Date();
      const options = { weekday: 'long' };
      const dayPart = now.toLocaleDateString('en-GB', options);
      const datePartOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const datePart = now.toLocaleDateString('en-GB', datePartOptions);
      const timePart = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).replace(':', '.');

      const timestamp = `${datePart} ${timePart.toLowerCase()}`;

      context.font = '16px Arial';
      context.fillStyle = textColor;
      context.strokeStyle = textColor === 'black' ? 'white' : 'black';
      context.lineWidth = 2;

      const x = 10;
      const yDay = canvasRef.current.height - 60;
      const yDate = canvasRef.current.height - 40;

      context.strokeText(dayPart, x, yDay);
      context.fillText(dayPart, x, yDay);
      context.strokeText(timestamp, x, yDate);
      context.fillText(timestamp, x, yDate);

      const dataUrl = canvasRef.current.toDataURL('image/png');
      setImageData(dataUrl);

      // Stop the video stream after capturing the image
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
      setCameraActive(false);
    }
  };

  const handleRetake = () => {
    setImageData(null);
    startCamera(); // Immediately re-open the camera
  };

  const handleSwitchCamera = () => {
    if (videoDevices.length > 1) {
      const nextCameraIndex = (videoDevices.findIndex(device => device.deviceId === currentCamera) + 1) % videoDevices.length;
      setCurrentCamera(videoDevices[nextCameraIndex].deviceId);
      startCamera(); // Restart the camera with the new device
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if the image has been captured
    if (!imageData) {
      setLocalError("Please capture an image before submitting.");
      return; // Prevent submission if no image
    }

    // Check if the image has been captured
    if (!clockoutreason) {
      setLocalError("Please include a reason");
      return; // Prevent submission if no image
    }

    onSuccessfulSubmit(clockoutreason, imageData); 
    onCl();
  };

  if (!isOp) return null;

  return (
    <div className="attendance-modal">
      <div className="attendance-modal-content">
      <div type="button" onClick={onCl} className="imf-closemodal"><AiOutlineClose /></div>
        {loading ? (
          <div className="rhombus">
            <img src='/images/rhombus.gif' alt="krafsync" />
          </div>
        ) : (
          
          <div className="inner-modal">
            <div className="im-error-msg">
              <div>{localError}</div>
            </div>
      
            <form onSubmit={handleSubmit} className="im-form">

              <div className="im-guidelines">To clock out, please capture a clear photo of yourself incuding the surrounding background for verification purposes. Additionally, kindly provide a brief reason for your clock-in such as 'Meeting Client outdoors' or any other relevant details.</div>

            <div>
              {hasCamera ? (
                <div>
                  {!imageData ? (
                    <div>
                      <div type="button" onClick={handleOpenCamera} style={{ display: cameraActive ? 'none' : 'block' }} className="handleOpenCamerabtn">
                        <div><RiCameraLensFill /></div>
                      </div>
                      <div className="video-cvs-cont" style={{ display: cameraActive ? 'block' : 'none' }}>
                        <video ref={videoRef} className="video-cvs" autoPlay style={{ display: cameraActive ? 'block' : 'none' }}></video>
                        {cameraActive && (
                        <div className="camera-act-cont">
                          <div type="button" onClick={takePicture} className="camera-snap">
                            <div><FaCamera /></div>
                          </div>
                          <div type="button" onClick={handleSwitchCamera} className="camera-switch" disabled={videoDevices.length <= 1}>
                          <MdCameraswitch />
                          </div>
                        </div>
                      )}

                      </div>
                  
                    </div>
                  ) : (
                    <div>
                      <img src={imageData} alt="Captured" style={{ width: '60px', height: '50px' }} />
                      <div type="button" onClick={handleRetake} className="imf-retake"><FaArrowRotateRight /></div>
                    </div>
                  )}
                  <canvas ref={canvasRef} width="200" height="150" style={{ display: 'none' }}></canvas>
                </div>
              ) : (
                <p>Device does not support camera functionality</p>
              )}
            </div>


              <div className="imf-reason">
                <label htmlFor="reason">Reason:</label>
                <textarea
                 id="clockoutreason"
                 value={clockoutreason}
                 onChange={(e) => setClockOutReason(e.target.value)}
                 required
                />
              </div>

              <div type="submit" className="imf-ci-btn" onClick={handleSubmit}>Clock Out</div>

            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceModalClockOut;
