import React, { useState } from 'react';

const TableSizeSelector = ({ onSelect }) => {
  const [rows, setRows] = useState(3);
  const [cols, setCols] = useState(3);

  const handleMouseEnter = (row, col) => {
    setRows(row);
    setCols(col);
  };

  return (
    <div className="table-size-selector">
      {[...Array(6)].map((_, rowIndex) => (
        <div key={rowIndex} className="row">
          {[...Array(6)].map((_, colIndex) => (
            <div
              key={colIndex}
              className={`cell ${rowIndex < rows && colIndex < cols ? 'selected' : ''}`}
              onMouseEnter={() => handleMouseEnter(rowIndex + 1, colIndex + 1)}
              onClick={() => onSelect(rowIndex + 1, colIndex + 1)}
            />
          ))}
        </div>
      ))}
      <div className="size-label">{`${rows} x ${cols}`}</div>
    </div>
  );
};

export default TableSizeSelector;
