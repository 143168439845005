import React from 'react'
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Navigation from '../landing/Navigation'
import Hero from '../landing/Hero'

const Landing = ({isAuthenticated}) => {
    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return (
      <main className="index-main roboto-regular">
        <Navigation />
        <div className='divider'></div>
        <Hero />
      </main>
    )
}


Landing.propTypes = {
    isAuthenticated: PropTypes.bool
  };

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Landing);