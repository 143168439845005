import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const AdminRoute = ({ element: Component, auth, ...rest }) => {
    const location = useLocation();

    const { isAuthenticated, user, loading = true } = auth; // Destructure auth here
    
    if (loading) return <div>Loading...</div>; // Or a loading spinner if preferred

    return (
        isAuthenticated && user.role === 'admin' ? (
            <Component {...rest} />
        ) : (
            <Navigate to='/login' state={{ from: location }} />
        )
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AdminRoute);
