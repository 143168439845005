import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser } from '../../actions/users';
import Spinner from '../layout/Spinner';

import UserDetails from './UserDetails';
import EmploymentDetails from './EmploymentDetails';
import LeaveEntitlements from './LeaveEntitlements';
import UserPermissions from './UserPermissions';

import { MdAdminPanelSettings } from "react-icons/md";

const EditUser = ({ getUser, user: {user, loading}  }) => {
    const { id } = useParams(); 
    const [activeOption, setActiveOption] = useState('option1');

    useEffect(() => {
        getUser(id);
    }, [getUser, id]);

    
    if (loading) {
        return <Spinner />;
      }

    const getButtonStyle = (option) => ({
        backgroundColor: activeOption === option ? 'rgb(52, 89, 154)' : 'transparent',
        color: activeOption === option ? 'white' : 'black',
    });
            
    return (
        <Fragment>
            <main className="ep-main roboto-regular">
                <div className="ep-container">
                    <div className="ep-left-wrapper">
                        <div className="ep-left-item">
                            <div className="ep-li-title">Accounts Centre</div>
                            <div className="ep-li-desc">Manage Employees Personal Details</div>

                            <div onClick={() => setActiveOption('option1')} style={getButtonStyle('option1')} className="ep-li-link">
                                <span><img src="/images/user-3-16.ico" alt="profile-icon" /></span>
                                <span>User Details</span>
                            </div>

                            <div onClick={() => setActiveOption('option2')} style={getButtonStyle('option2')} className="ep-li-link">
                                <span><img src="/images/edit-user-16.ico" alt="profile-icon" /></span>
                                <span>Employment Details</span>
                            </div>

                            <div onClick={() => setActiveOption('option3')} style={getButtonStyle('option3')} className="ep-li-link">
                                <span><img src="/images/padlock-4-16.ico" alt="profile-icon" /></span>
                                <span>Entitled Leaves</span>
                            </div>

                            <div onClick={() => setActiveOption('option4')} style={getButtonStyle('option4')} className="ep-li-link">
                                <span><MdAdminPanelSettings /></span>
                                <span>Permissions</span>
                            </div>
                        </div>
                    </div>

                    <div className="ep-right-wrapper">
                        {activeOption === 'option1' && <UserDetails user={user} />}
                        {activeOption === 'option2' && <EmploymentDetails user={user} />}
                        {activeOption === 'option3' && <LeaveEntitlements user={user} />}
                        {activeOption === 'option4' && <UserPermissions user={user} />}
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    user: state.users
});

export default connect(mapStateToProps, { getUser })(EditUser);
