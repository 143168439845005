import React, { Fragment, useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import Alert from '../layout/Alert'; 
import { resetPassword } from '../../actions/auth';
import { Link } from 'react-router-dom';

const ResetPassword = ({ setAlert, resetPassword }) => {
    const [redirect, setRedirect] = useState(false);
    const { token } = useParams(); 
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    const { password, confirmPassword } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();

        // Check if passwords match
        if (password !== confirmPassword) {
            setAlert('Passwords do not match', 'danger');
            return; // Exit if passwords do not match
        } 

        // Proceed with resetting the password
        await resetPassword(password, token);
        setAlert('Password changed successfully! You will be redirected to the login page in 3 seconds.', 'green');

        // Set a timer to trigger redirection after 3 seconds
        setTimeout(() => {
            setRedirect(true); // Change redirect state to true after 3 seconds
        }, 3000); // 3000 milliseconds = 3 seconds
    };   

    // Redirect if state is true
    if (redirect) {
        return <Navigate to='/Login' />;
    }
            
    return (
        <Fragment>
            <main className="index-main roboto-regular">
            <div className='lp-nav-container'>
            <div className='lp-nav-innercont'>
                <Link to='/' className="lp-nav-logo">
                <img src='/images/krafsync_logo.png' alt='krafsync'/>
                </Link>
            </div>
            </div>
            <div className='divider'></div>
            <div className='login-main'>
            <div className='login-container'>
                <Alert />
                <form className="login-form roboto-bold" onSubmit={onSubmit}>
                <div className="login-form-container">
                    <fieldset>
                        <label htmlFor="password">Password</label>
                        <input 
                            type="password" 
                            id="password" 
                            name="password" 
                            value={password} 
                            onChange={onChange} 
                            required 
                        />

                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input 
                            type="password" 
                            id="confirmPassword" 
                            name="confirmPassword" 
                            value={confirmPassword} 
                            onChange={onChange} 
                            required 
                        />

                        <button type="submit" className="roboto-bold">Reset Password</button>
                    </fieldset>

                    <div className="lfc-btm">
                    <Link to='/Register' className='register-link'>Create an account</Link>
                    </div>
                </div>
                </form>
            </div>
            </div>
        </main>
        </Fragment>  
    );    
};    

ResetPassword.propTypes = {
    setAlert: PropTypes.func.isRequired,
    resetPassword: PropTypes.shape({
        success: PropTypes.bool.isRequired,
    }).isRequired,
};

export default connect(null, { setAlert, resetPassword })(ResetPassword);
