import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const LeaveApproved = () => {

    return (
        <Fragment>
            <div className="black-background roboto-regular">
                <main className="LeaveApproved-main">
                    <div className="redirect-header">
                        <div className="commissioner-logo logo-la">krafsync</div>
                    </div>

                    <div className="redirect-main">
                        <h1 className="redirect-msg">Leave has been approved!</h1>
                        
                        <div>A notification email has been forwarded to the recipient</div>
                        <div>Login to your account</div>

                        <Link to="/Login" className="redirect-btn">Login</Link>
                    </div>

                    <div className="redirect-img">
                        <img src="/images/crowd.jpg" alt="leave-approved" />
                    </div>
                </main>
            </div>
        </Fragment>
    );
};

export default LeaveApproved;
