import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clockIn, clockOut, takeBreak, fetchClockState } from '../../actions/attendance';
import AttendanceModal from './AttendanceModal';
import AttendanceModalClockOut from './AttendanceModalClockOut';

import { FaRegClock } from "react-icons/fa";
import { IoFastFoodOutline } from "react-icons/io5";

const Attendance = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [elapsedTime, setElapsedTime] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [comodalOpen, setcoModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { attendance, loading, error, message } = useSelector((state) => state.attendance);

  useEffect(() => {
    // Fetch clock state on mount
    dispatch(fetchClockState());
  }, [dispatch]);
  
  useEffect(() => {
    // Fetch location initially on component mount
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (err) => console.error("Error getting location: ", err),
      { enableHighAccuracy: true }
    );      
  
    // Set up a background interval to refresh location every 15 minutes
    const locationInterval = setInterval(() => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          console.log("Location updated in background.");
        },
        (err) => console.error("Error updating location:", err),
        { enableHighAccuracy: true }
      );
    }, 15 * 60 * 1000); // Every 15 minutes
  
    // Clear the interval when component unmounts
    return () => clearInterval(locationInterval);
  }, []); // Only run on mount              
  
  useEffect(() => {
    // Check if todayAttendance is available and handle elapsed time logic
    if (attendance?.todayAttendance) {
      const userId = attendance.todayAttendance.user;
  
      // Fetch elapsed time and last clock-in date from localStorage
      const savedElapsedTime = localStorage.getItem(`elapsedTime_${userId}`);
      const savedDate = localStorage.getItem(`lastClockInDate_${userId}`);
      const currentDate = new Date().toLocaleDateString();
  
      // If the user is present, check the saved date and elapsed time
      if (attendance.todayAttendance.isPresent) {
        if (savedDate === currentDate && savedElapsedTime !== null) {
          // Resume the previous elapsed time if it's the same day and savedElapsedTime exists
          setElapsedTime(parseInt(savedElapsedTime, 10) || 0);
        } else {
          // It's a new day or new user, initialize localStorage for this user
          localStorage.setItem(`elapsedTime_${userId}`, '0'); // Create a new entry for elapsed time
          localStorage.setItem(`lastClockInDate_${userId}`, currentDate); // Store the current date
          setElapsedTime(0); // Start fresh since it's a new day
        }
      } else {
        // User is not present, reset elapsed time
        setElapsedTime(0); // Reset elapsed time or keep existing logic based on your needs
      }
    }
  }, [attendance]); // Dependency on attendance
  

  useEffect(() => {
    let interval;

    if (attendance?.todayAttendance?.isPresent) {
      interval = setInterval(() => {
        setElapsedTime((prevTime) => {
          const newTime = prevTime + 60; // Increment elapsed time by 1 minute
          const userId = attendance.todayAttendance.user;

          // Save the updated elapsed time in localStorage if userId exists
          if (userId) {
            localStorage.setItem(`elapsedTime_${userId}`, newTime);
          }
          return newTime;
        });
      }, 60000); // Update every minute
    }

    return () => clearInterval(interval); // Cleanup interval
  }, [attendance?.todayAttendance?.isPresent]);

  useEffect(() => {
    if (error === 'You are not within the allowed office location') {
      setModalOpen(true); // Open the modal
    }

    if (error === 'You are not within the allowed office locationa') {
      setcoModalOpen(true); // Open the modal
    }
  }, [error]);

  const handleClockIn = async () => {    
    if (location.latitude && location.longitude) {
      await dispatch(clockIn(location.latitude, location.longitude));

      // Only access todayAttendance if it exists
      if (attendance?.todayAttendance) {
        const userId = attendance.todayAttendance.user;

        if (userId) {
          const savedDate = localStorage.getItem(`lastClockInDate_${userId}`);
          const currentDate = new Date().toLocaleDateString();

          // Check if it's a new day; if so, reset the elapsed time
          if (savedDate !== currentDate) {
            setElapsedTime(0);
            localStorage.setItem(`elapsedTime_${userId}`, 0);
          }

          // Save the current clock-in date for this user
          localStorage.setItem(`lastClockInDate_${userId}`, currentDate);
        }
      }
    } else {
      console.error("Location not available for Clock In.");
    }
  }; 

  const handleClockOut = async () => {
    if (location.latitude && location.longitude) {
      await dispatch(clockOut(location.latitude, location.longitude));

      // Only access todayAttendance if it exists
      if (attendance?.todayAttendance) {
        const userId = attendance.todayAttendance.user;

        if (userId) {
          // Save the current elapsed time to localStorage when the user clocks out
          localStorage.setItem(`elapsedTime_${userId}`, elapsedTime);
          setElapsedTime(0); // Reset elapsed time after clock out
        }
      }
    } else {
      console.error("Location not available for Clock Out.");
    }
  };

  const handleAlternateClockIn = async (reason, imageData) => {
    await dispatch(clockIn(location.latitude, location.longitude, reason, imageData));

    // Only access todayAttendance if it exists
    if (attendance?.todayAttendance) {
      const userId = attendance.todayAttendance.user;

      if (userId) {
        const savedDate = localStorage.getItem(`lastClockInDate_${userId}`);
        const currentDate = new Date().toLocaleDateString();

        // Check if it's a new day; if so, reset the elapsed time
        if (savedDate !== currentDate) {
          setElapsedTime(0);
          localStorage.setItem(`elapsedTime_${userId}`, 0);
        }

        // Save the current clock-in date for this user
        localStorage.setItem(`lastClockInDate_${userId}`, currentDate);
      }
    }
  };

  const handleAlternateClockOut = async (clockoutreason, imageData) => {
    await dispatch(clockOut(location.latitude, location.longitude, clockoutreason, imageData));

    // Only access todayAttendance if it exists
    if (attendance?.todayAttendance) {
      const userId = attendance.todayAttendance.user;

      if (userId) {
        // Save the current elapsed time to localStorage when the user clocks out
        localStorage.setItem(`elapsedTime_${userId}`, elapsedTime);
        setElapsedTime(0); // Reset elapsed time after clock out
      }
    }
  };

  const handleBreak = async () => {
    if (attendance?.todayAttendance?.isPresent) {
      if (location.latitude && location.longitude) {
        await dispatch(takeBreak(location.latitude, location.longitude));
        console.log('Break started.');
  
        // Only access todayAttendance if it exists
        if (attendance?.todayAttendance) {
          const userId = attendance.todayAttendance.user;
  
          if (userId) {
            // Save the current elapsed time to localStorage when the user goes on break
            localStorage.setItem(`elapsedTime_${userId}`, elapsedTime);
            setElapsedTime(0); // Reset elapsed time after starting the break
          }
        }
      } else {
        console.error('Location not available for Break.');
      }
    } else {
      console.error('You must be present to start a break.');
    }
  };
  

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
  
    if (h > 0) {
      return `${h} hour${h > 1 ? 's' : ''} ${m} minute${m !== 1 ? 's' : ''}`;
    } else {
      return `${m} minute${m !== 1 ? 's' : ''}`;
    }
  };
  

  return (
    <Fragment>
      {!attendance ? (
        <p>Loading...</p>
      ) : (
        <Fragment>
          {attendance?.todayAttendance?.isPresent ? (
            <div className="ac-main">
              <div className="attendance-component-header">
                <div className="ach-cont">
                {error && <p>{error}</p>}
                {message && <p>{message}</p>}
                <p>
                  {attendance.todayAttendance.records.length > 0 
                    ? new Date(attendance.todayAttendance.records[attendance.todayAttendance.records.length - 1]?.checkInTime).toLocaleString() 
                    : "No record found"}
                </p>
                <p>Duration: {formatTime(elapsedTime)}</p>
                </div>
              </div>

              <div className="attendance-component">
  
                  <div onClick={handleClockOut} className="attendance-btn ab-clockout">
                    <div><FaRegClock /></div>
                    <div>Clock Out</div>
                  </div>
                  {attendance?.todayAttendance?.isBreak === 'true' ? (
                    <div className="attendance-btn ab-break">
                      <div><IoFastFoodOutline /></div>
                      <div>Break</div>
                  </div>
                  ) : (
                    <div className="attendance-btn" onClick={handleBreak}>
                      <div><IoFastFoodOutline /></div>
                      <div>Break</div>
                  </div>
                  )}
                </div>
  
                
            </div>
          ) : (
            <div className="ac-main">
              <div className="attendance-component-header">
                <div className='ach-cont'>
                  {error && <p>{error}</p>}
                  {message && <p>{message}</p>}
                </div>
              </div>

              <div className="attendance-component">
                <div onClick={handleClockIn} className="attendance-btn ab-clockin">
                  <div><FaRegClock /></div>
                  <div>Clock In</div>
                </div>

                {attendance?.todayAttendance?.isBreak === 'true' ? (
                  <div className="attendance-btn ab-break">
                    <div><IoFastFoodOutline /></div>
                    <div>Break</div>
                </div>
                ) : (
                  <div className="attendance-btn">
                    <div><IoFastFoodOutline /></div>
                    <div>Break</div>
                </div>
                )}

              </div>
            </div>
          )}
        </Fragment>
      )}

      <AttendanceModal 
        errorMessage={error} 
        isOpen={modalOpen} 
        onClose={() => setModalOpen(false)} 
        onSuccessfulSubmit={handleAlternateClockIn} 
      />

      <AttendanceModalClockOut 
        errorMessage={error} 
        isOp={comodalOpen} 
        onCl={() => setcoModalOpen(false)} 
        onSuccessfulSubmit={handleAlternateClockOut} 
      />
    </Fragment>
  );
};

export default Attendance;
