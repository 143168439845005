import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { BsArrowUpRightCircle } from "react-icons/bs";
import { RiShoppingBagLine } from "react-icons/ri";
import { GoPeople, GoStack } from "react-icons/go";

const Recruitment = () => {

  return (
    <Fragment>
      <div className="grid-section-main">
            <div className="gsm-title">
                <div className="adm-db-people-title">Recruitment</div>
                <div><Link to="/new-user" className="icon-reset"><BsArrowUpRightCircle /></Link></div>
            </div>

            <div className="gsm-content">
                <div className="gsm-r-grid">
                    <div className="gsm-r-item gsm-r-item1">
                    <div><RiShoppingBagLine /></div>
                    <div>Jobs</div>
                    </div>

                    <div className="gsm-r-item gsm-r-item2">
                    <div><GoPeople /></div>
                    <div>Candidates</div>
                    </div>
                </div>

                <div className="gsm-r-item3">
                    <div className="gsm-i3-title">
                        <div><GoStack /></div>
                        <div>Career Site</div>
                    </div>
                    <Link to="/new-user" className="people-btn roboto-bold">&#x2b; Employee Onboarding</Link>
                </div>
            </div>
        </div>
    </Fragment>
  );
};


export default Recruitment;
