import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = ({ element: Component, auth: { isAuthenticated = false, loading = true }, ...rest }) => {
    const location = useLocation();
    
    if (loading) return null; // Or a loading spinner if preferred

    return (
        isAuthenticated ? (
            <Component {...rest} />
        ) : (
            <Navigate to='/Login' state={{ from: location }} />
        )
    );
};

PrivateRoute.propTypes = {
    auth: PropTypes.shape({
        isAuthenticated: PropTypes.bool,
        loading: PropTypes.bool
    }).isRequired,
    element: PropTypes.elementType.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
