import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const PasswordAndSecurity = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (token) setAuthToken(token);

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    try {
      const response = await axios.put('/api/auth/pswd', {
        currentPassword,
        newPassword,
      }, config);

      setMessage('Password updated successfully!');
      setMessageType('success');
      setCurrentPassword('');
      setNewPassword('');
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          setMessage('Incorrect current password. Please try again.');
        } else if (err.response.status === 500) {
          setMessage('Server error. Please try again later.');
        } else {
          setMessage(err.response.data.msg || 'An error occurred.');
        }
      } else {
        setMessage('An error occurred. Please check your network connection.');
      }
      setMessageType('error');
    }
  };

  return (
    <div className='ep-pd roboto-regular'>
      <div className="ep-li-title">Password and Security</div>
      <div className="ep-li-desc">Manage your password and security</div>

      <form onSubmit={handleSubmit} className="pwd-form">
        <div>
          <label htmlFor="currentPassword">
            Current Password:
          </label>
          <input
            id="currentPassword"
            type={showCurrentPassword ? 'text' : 'password'}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder="Enter your current password"
            style={{ padding: '8px', marginRight: '8px', width: '90%' }}
          />
          <button
            type="button"
            onClick={toggleCurrentPasswordVisibility}
            style={{
              cursor: 'pointer',
              border: 'none',
              background: 'none'
            }}
          >
            {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>

        <div>
          <label htmlFor="newPassword" style={{ marginRight: '8px' }}>
            New Password:
          </label>
          <input
            id="newPassword"
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter your new password"
            style={{ padding: '8px', marginRight: '8px', width: '90%' }}
          />
          <button
            type="button"
            onClick={toggleNewPasswordVisibility}
            style={{
              cursor: 'pointer',
              border: 'none',
              background: 'none'
            }}
          >
            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        <br/>
        <button type="submit" style={{ padding: '8px 16px' }}>
          Update Password
        </button>
      </form>

      {message && (
        <p
          style={{
            marginTop: '16px',
            fontWeight: '700',
            color: messageType === 'success' ? 'green' : 'red'
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default PasswordAndSecurity;
