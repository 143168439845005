import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';

import {
    GET_PROFILE,
    PROFILE_ERROR,
} from './types';

//GET CURRENT USER PROFILE
export const getCurrentProfile = () => async dispatch => {
    if(localStorage.token) {
        setAuthToken(localStorage.token);
    }
    
    try {
        const res= await axios.get('/api/profile/me');

        dispatch({
            type: GET_PROFILE,
            payload: res.data
        });
    } catch(err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

//GET SINGLE PROFILE BY ID PARAM
export const getProfile = (id) => async dispatch => {

    try {
        const res= await axios.get(`/api/profile/user/${id}`);

        dispatch({
            type: GET_PROFILE,
            payload: res.data
        });
    } catch(err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}