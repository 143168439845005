import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

import { FaRegBuilding } from "react-icons/fa";

const CompanyDetails = ({ company }) => {
    const [formData, setFormData] = useState({
        companyName: '',
        companyNo: '',
        companyAddress1: '',
        companyAddress2: '',
        companyAddress3: '',
        companyContact: '',
        companyEmail: '',
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (company) {
            setFormData({
                companyName: company.companyName || '',
                companyNo: company.companyNo || '',
                companyAddress1: company.companyAddress1 || '',
                companyAddress2: company.companyAddress2 || '',
                companyAddress3: company.companyAddress3 || '',
                companyContact: company.companyContact || '',
                companyEmail: company.companyEmail || ''
            });
        }
    }, [company]);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handlePersonalDetails = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const res = await axios.put('/api/company', formData, config);

            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('Company details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update company details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors);
            } else {
                console.error('Error updating user details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">Company Details <FaRegBuilding /> </div>
            <div className="ep-li-desc">We use this information to populate your company online identity across all our associated platforms making your business more visible on the internet</div>

            <form className="ep-form" onSubmit={handlePersonalDetails}>
                <label htmlFor="userName">Company:</label>
                <input type="text" id="companyName" name="companyName" value={formData.companyName} onChange={onChange} />

                <label htmlFor="userName">Registration No:</label>
                <input type="text" id="companyNo" name="companyNo" value={formData.companyNo} onChange={onChange} />

                <label htmlFor="email">Company Email:</label>
                <input type="email" id="companyEmail" name="companyEmail" value={formData.companyEmail} onChange={onChange} />

                <label htmlFor="Address">Address:</label>
                <input type="text" id="companyAddress1" name="companyAddress1" placeholder="Address Line 1" value={formData.companyAddress1} onChange={onChange} required />
                <input type="text" id="companyAddress2" name="companyAddress2" placeholder="Address Line 2" value={formData.companyAddress2} onChange={onChange} required />
                <input type="text" id="companyAddress3" name="companyAddress3" placeholder="Address Line 3" value={formData.companyAddress3} onChange={onChange} />

                <label htmlFor="contact">Company Contact No:</label>
                <input type="text" id="companyContact" name="companyContact" value={formData.companyContact} onChange={onChange} placeholder='Please key in Company Contact e.g +6 0X-XXXXXXX' />

                <div className='m-y-divider'></div>

                <button type="submit" className='ep-btn'>Update Company Details</button>
            </form>

            <div className='m-y-divider'></div>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CompanyDetails;

