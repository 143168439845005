import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getUsers } from '../../actions/users';
import Spinner from '../layout/Spinner';
import OurPeople from '../component/People.jsx';

const People = ({ getUsers, auth: { user }, users: { users, loading } }) => {

    useEffect(() => {
        getUsers();
      }, [getUsers]);
    
      if (loading) {
        return <Spinner />;
      }

    return (
        <Fragment>
            <main className="user-dashboard roboto-regular">
                <div className="welcome-user">
                    <span>Our People</span>
                </div>
                <div className='people-dashboard'>
                    <OurPeople users={users} admin={user}/>
                </div>
            </main>
        </Fragment>
    );
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
    users: state.users,
});

export default connect(mapStateToProps, { getUsers })(People);
