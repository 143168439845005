import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, Circle, useLoadScript } from '@react-google-maps/api';
import { MdMyLocation } from "react-icons/md";
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const defaultCenter = { lat: 37.4419, lng: -122.1430 };
const libraries = ['places'];

const CompanyAttendance = ({ company }) => {
  const [formData, setFormData] = useState({
    officeLocations: [],
    workHours: Array(7).fill({ day: '', isWorkDay: false, startTime: '', endTime: '', lunchStartTime: '', lunchEndTime: '' }),
  });
  const [radius, setRadius] = useState(100); // Default radius
  const [zoom, setZoom] = useState(10); // Default zoom level
  const autocompleteRefs = useRef([]); // Array of refs for autocomplete inputs
  const [currentLocationIndex, setCurrentLocationIndex] = useState(null);
  const inputRefs = useRef([]); // Array of refs for input elements
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCzz-k-7CmQN7FkCMG3Z13jmIcRtCVsMrc', // Replace with your actual API key
    libraries,
  });
  const [removedLocations, setRemovedLocations] = useState([]);


  useEffect(() => {
    if (isLoaded && window.google) {
      formData.officeLocations.forEach((_, index) => {
        if (inputRefs.current[index]) {
          autocompleteRefs.current[index] = new window.google.maps.places.Autocomplete(inputRefs.current[index]);

          autocompleteRefs.current[index].addListener('place_changed', () => {
            const place = autocompleteRefs.current[index].getPlace();
            if (place.geometry) {
              const newLocation = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              handleLocationChange(newLocation.lat, newLocation.lng, index);
              setZoom(15); // Zoom into the selected location
            }
          });
        }
      });
    }
  }, [isLoaded, formData.officeLocations]);

  useEffect(() => {
    if (company) {
      // Update officeLocations from company data
      const officeLocations = company.officeLocations.map(loc => ({
        _id: loc._id,
        officeName: loc.officeName,
        latitude: loc.officeLocation.latitude,
        longitude: loc.officeLocation.longitude,
        radius: loc.radius || 100, // Default to 100 if no radius
      }));

      // Update workHours from company data
      const workHours = company.workHours
        ? company.workHours.map((wh, index) => ({
            day: wh.day || '',
            isWorkDay: wh.isWorkDay || false,
            startTime: wh.startTime || '',
            endTime: wh.endTime || '',
            lunchStartTime: wh.lunchStartTime || '',
            lunchEndTime: wh.lunchEndTime || '',
          }))
        : Array(7).fill({
            day: '',
            isWorkDay: false,
            startTime: '',
            endTime: '',
            lunchStartTime: '',
            lunchEndTime: ''
          });

      setFormData({
        officeLocations,
        workHours,
      });
    }
  }, [company]);

  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);

  // Function to get current location
  const getCurrentLocation = (index) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          handleLocationChange(latitude, longitude, index);
          setZoom(15); // Zoom into the current location
        },
        (error) => {
          console.error('Error retrieving location:', error);
          alert('Error retrieving location. Please enable location services.');
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  // Handle location change for a specific index
  const handleLocationChange = (lat, lng, index) => {
    setFormData((prevData) => {
      const newLocations = [...prevData.officeLocations];
      newLocations[index] = { ...newLocations[index], latitude: lat, longitude: lng };
      return { ...prevData, officeLocations: newLocations };
    });
  };

  const handleRadiusChange = (event, index) => {
    const newRadius = parseInt(event.target.value) || 0;
    setRadius(newRadius);
    setFormData((prevData) => {
      const newLocations = [...prevData.officeLocations];
      newLocations[index] = { ...newLocations[index], radius: newRadius };
      return { ...prevData, officeLocations: newLocations };
    });
  };

  const handleMapClick = (e, index) => {
    const newLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    handleLocationChange(newLocation.lat, newLocation.lng, index);
  };

  const handleWorkHoursChange = (index, field, value) => {
    setFormData((prevData) => {
      const newWorkHours = [...prevData.workHours];
      newWorkHours[index][field] = value;
      return { ...prevData, workHours: newWorkHours };
    });
  };

  const addNewLocation = () => {
    setFormData((prevData) => ({
      ...prevData,
      officeLocations: [...prevData.officeLocations, { officeName: '', latitude: '', longitude: '', radius: 100 }],
    }));
  };

  const removeLocation = (index) => {
    setFormData((prevData) => {
      const newLocations = [...prevData.officeLocations];
      const removedLocation = newLocations[index]; // Get the location to remove
  
      newLocations.splice(index, 1); // Remove it from the formData
  
      // Add the removed location to the removedLocations array
      setRemovedLocations((prevRemoved) => [
        ...prevRemoved,
        { ...removedLocation, remove: true },
      ]);
  
      return { ...prevData, officeLocations: newLocations };
    });
  };
  

  const onSubmit = async (event) => {
    event.preventDefault();
  
    const token = localStorage.getItem('token');
    if (token) setAuthToken(token);
  
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
  
    const payload = {
      officeLocations: [
        // Include active office locations
        ...formData.officeLocations.map(loc => ({
          _id: loc._id,
          officeName: loc.officeName,
          officeLocation: {
            latitude: loc.latitude,
            longitude: loc.longitude,
          },
          radius: loc.radius,
        })),
        // Include removed office locations with remove: true
        ...removedLocations.map(loc => ({
          _id: loc._id,
          officeName: loc.officeName,
          officeLocation: {
            latitude: loc.latitude,
            longitude: loc.longitude,
          },
          radius: loc.radius,
          remove: true, // Mark as removed
        }))
      ],
      workHours: formData.workHours.map(wh => ({
        day: wh.day,
        isWorkDay: wh.isWorkDay,
        startTime: wh.startTime,
        endTime: wh.endTime,
        lunchStartTime: wh.lunchStartTime,
        lunchEndTime: wh.lunchEndTime,
      })),
    };
    
  
    try {
  
      const res = await axios.put('/api/company/location', payload, config);
  
      if (res.status === 200 || res.status === 201) {
          setFeedbackMessage('Company details updated successfully.');
      } else {
          setFeedbackMessage('Failed to update company details.');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
          setValidationErrors(error.response.data.errors);
      } else {
          console.error('Error updating user details:', error);
          setFeedbackMessage('An error occurred. Please try again later.');
      }
    }
  };
  

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <form onSubmit={onSubmit}>
      <div>
        <div className="ep-li-title">Attendance and Organization's Location Records</div>
        <div className="ep-li-desc">
          Set up a Geolocation attendance system that allows employees to clock in and out of work within a certain geographical radius
        </div>

        <button type="button" className="maps-btn" onClick={addNewLocation}>
          Add New Location
        </button>

        {formData.officeLocations.map((location, index) => (
          <div key={index} className="maps-container" style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
            <input
              required
              value={location.officeName}
              onChange={(e) => {
                const newOfficeName = e.target.value;
                setFormData((prevData) => {
                  const newLocations = [...prevData.officeLocations];
                  newLocations[index] = { ...newLocations[index], officeName: newOfficeName };
                  return { ...prevData, officeLocations: newLocations };
                });
              }}
              placeholder={`Location ${index + 1}`}
              className="maps-name"
            />

            <div className="maps-search-container">
              <button type="button" onClick={() => getCurrentLocation(index)}>
                <MdMyLocation />
              </button>

              <input
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                placeholder="Search a location..."
                onFocus={() => setCurrentLocationIndex(index)}
                style={{ marginBottom: '10px', width: '100%' }}
              />

            </div>

            <GoogleMap
              zoom={zoom}
              center={location.latitude && location.longitude ? { lat: location.latitude, lng: location.longitude } : defaultCenter}
              mapContainerStyle={{ width: '100%', height: '400px' }}
              onClick={(e) => handleMapClick(e, index)}
            >
              <Marker position={location.latitude && location.longitude ? { lat: location.latitude, lng: location.longitude } : defaultCenter} />
              <Circle
                center={location.latitude && location.longitude ? { lat: location.latitude, lng: location.longitude } : defaultCenter}
                radius={location.radius || 100}
                options={{
                  strokeColor: '#0000FF', // Red stroke color for the border
                  strokeOpacity: 1, // Fully opaque stroke
                  strokeWeight: 4, // Increase width if needed for visibility
                  fillColor: '#0000FF', // No fill
                  fillOpacity: 0.5, // Transparent fil
                }}
              />
            </GoogleMap>

            <div className='maps-hidden'>
              <label>Latitude</label>
              <input value={location.latitude || ''} readOnly className="input-text" />

              <label>Longitude</label>
              <input value={location.longitude || ''} readOnly className="input-text" />
            </div>

            <div className="maps-radius">
              <label>Radius (meters)</label>
              <input type="number" value={location.radius} onChange={(e) => handleRadiusChange(e, index)} className="input-text" />

              <button type="button" className="maps-btn" onClick={() => removeLocation(index)}>
                Remove Location
              </button>
            </div>
          </div>
        ))}
      </div>

      <div>
        <div className="ep-li-title">Working Hours</div>
        <div className="ep-li-desc">Define working hours for each day of the week</div>

        {formData.workHours.map((wh, index) => (
          <div key={index} className="work-hours-row cs-a-wh-container">

            <div className="wh-title">
              <input
                type="checkbox"
                checked={wh.isWorkDay}
                onChange={(e) => handleWorkHoursChange(index, 'isWorkDay', e.target.checked)}
              />

              <input
                type="text"
                value={wh.day}
                placeholder="Day"
                className="work-hours-input"
                readOnly
                onChange={(e) => handleWorkHoursChange(index, 'day', e.target.value)}
              />
            </div>

            <div className="wh-time">
              <div>
                <div>Start Time</div>
                <input
                  type="time"
                  value={wh.startTime}
                  onChange={(e) => handleWorkHoursChange(index, 'startTime', e.target.value)}
                  disabled={!wh.isWorkDay}
                  placeholder="Start Time"
                />
              </div>
              <div>
                <div>End Time</div>
                <input
                  type="time"
                  value={wh.endTime}
                  onChange={(e) => handleWorkHoursChange(index, 'endTime', e.target.value)}
                  disabled={!wh.isWorkDay}
                  placeholder="End Time"
                />
              </div>
            </div>

            <div className="wh-time">
              <div>
                <div>Lunch Start</div>
                  <input
                  type="time"
                  value={wh.lunchStartTime}
                  onChange={(e) => handleWorkHoursChange(index, 'lunchStartTime', e.target.value)}
                  disabled={!wh.isWorkDay}
                  placeholder="Lunch Start Time"
                />
              </div>
              <div>
                <div>Lunch End</div>
                <input
                  type="time"
                  value={wh.lunchEndTime}
                  onChange={(e) => handleWorkHoursChange(index, 'lunchEndTime', e.target.value)}
                  disabled={!wh.isWorkDay}
                  placeholder="Lunch End Time"
                />
              </div>
              
            </div>

          </div>
        ))}
      </div>

      <br/><br/>

      <button type="submit" className="ep-btn">Save Changes</button>

      <div className='m-y-divider'></div>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
            
    </form>
  );
};

export default CompanyAttendance;


