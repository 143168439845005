import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { GrClose } from "react-icons/gr";

const PeopleIcon = ({ users }) => {
    const [showAll, setShowAll] = useState(false);
    const modalRef = useRef(null);

    // Function to determine the correct avatar URL
    const loadAvatar = (user) => {
        if (user) {
            if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
                return `https:${user.avatar}`;
            } else if (user.avatarUrl) {
                return user.avatarUrl;
            } else {
                return '/path-to-default-avatar.jpg';
            }
        } else {
            return '/path-to-default-avatar.jpg';
        }
    };

    // Function to close modal if clicked outside
    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setShowAll(false);
        }
    };

    useEffect(() => {
        if (showAll) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showAll]);

    const firstTenUsers = users.slice(0, 20);

    return (
        <Fragment>
            <div className={`roboto-regular ${users.length < 5 ? 'small-grid' : 'adm-mobilegrid-section'}`}>
                {firstTenUsers.map(user => (
                    <div key={user._id} className="grid-mobilesection-item">
                        <Link to={`/admin/${user._id}`} className="contact-item-img">
                            <img 
                                src={loadAvatar(user)} 
                                alt={user.name ? user.name : 'User Avatar'} 
                                className="user-avatar"
                            />
                            <div className="hover-box">
                                {user.name}
                            </div>
                        </Link>
                    </div>

                    
                ))}

                {/* Modal for all users */}
                {showAll && (
                    <div className="people-modal-overlay">
                        <div className="people-modal-content" ref={modalRef}>
                            <button className="close-btn" onClick={() => setShowAll(false)}><GrClose /></button>
                            <div className="all-users-grid">
                                {users.map(user => (
                                    <div key={user._id} className="grid-mobilesection-item">
                                        <Link to={`/admin/${user._id}`} className="contact-item-img">
                                            <img 
                                                src={loadAvatar(user)} 
                                                alt={user.name ? user.name : 'User Avatar'} 
                                                className="user-avatar"
                                            />
                                            <div className="hover-box">
                                                {user.name}
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>          
                        </div>       
                    </div>
                )}
            </div>
            
            
            {/* Show toggle button if there are more than 10 users */}
            {users.length > 20 && (
                <div>
                            <button className="people-toggle-btn" onClick={() => setShowAll(true)}>
                    More People
                </button>
                </div>
            )}
        </Fragment>
    );
};

PeopleIcon.propTypes = {
    users: PropTypes.array.isRequired,
};

export default PeopleIcon;
