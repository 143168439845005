import React, { Fragment } from 'react';
import spinner from '../../assets/images/rhombus.gif';

const Spinner = () => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
        width: '100%',
    }}>
        <img src={spinner} alt="Loading..." style={{ width: '50px', height: '50px' }} />
    </div>
);

export default Spinner;
