import React, { Fragment, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import Alert from '../layout/Alert'; 
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { HiArrowNarrowLeft } from "react-icons/hi";

const Register = ({ setAlert, register, isAuthenticated }) => {
    const [step, setStep] = useState(1); // Track the current step
    const [loading, setLoading] = useState(false); // Track loading state
    const [formData, setFormData] = useState({
        companyName: '',
        companyNo: '',
        companyContact: '',
        name: '',
        email: '',
        password: '',
        password2: ''
    });

    const { companyName, companyNo, companyContact, name, email, password, password2 } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    // Validate the email format
    const isEmailValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    // Validate if all fields in step 1 are filled and valid
    const isStepOneValid = () => {
        if (!name || !email || !password || !password2) return false; // All fields must be filled
        if (!isEmailValid(email)) return false; // Email must be valid
        if (password !== password2) return false; // Passwords must 
        return true;
    };

    // Simulate a loading process for 3 seconds and then move to step 2
    const simulateLoadingAndNextStep = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setStep(2); // Move to Company Details section
        }, 5000);
    };

    // Handle the form validation and transition logic
    const handleNextStep = () => {
        setTimeout(() => {
            if (isStepOneValid()) {
                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                    setStep(2); // Move to Company Details section
                }, 5000); // Loading for 5 seconds
            } else {
                if (password !== password2) {
                    setAlert('Passwords do not match', 'danger');
                } else if (!isEmailValid(email)) {
                    setAlert('Please enter a valid email address', 'danger');
                } else {
                    setAlert('Please fill out all fields in Step 1', 'danger');
                }
            }
        }, 3000);
    
    };

    // Handle moving back to the previous step
    const onPreviousStep = () => {
        resetForm(); // Clear fields when going back
        setStep(1); // Go back to Admin Details section
    };

    // Reset the form on error
    const resetForm = () => {
        setFormData({
            companyName: '',
            companyNo: '',
            companyContact: '',
            name: '',
            email: '',
            password: '',
            password2: ''
        });
        setStep(1);
        setLoading(false); // Reset loading
    };

    // Handle the form submission
    const onSubmit = async e => {
        e.preventDefault();
        if (password !== password2) {
            setAlert('Passwords do not match', 'danger');
            resetForm(); // Reset on error
        } else {
            try {
                await register({ companyName, companyNo, name, email, password });
                setAlert('Successfully Registered, Check your email', 'green');
            } catch (error) {
                resetForm(); // Reset on submission error
            }
        }
    };

    // Redirect if already authenticated
    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <Fragment>
        <main className="index-main roboto-regular">
          <div className='lp-nav-container'>
            <div className='lp-nav-innercont'>
              <Link to='/' className="lp-nav-logo">
                <img src='/images/krafsync_logo.png' alt='krafsync'/>
              </Link>
            </div>
          </div>
          <div className='divider'></div>
          <div className='login-main'>
            <div className='login-container'>
              <Alert />
              <form className="login-form roboto-bold" onSubmit={e => onSubmit(e)}>
                <div className="login-form-container">
                
                {/* Step 1: Admin Details */}
                {!loading && step === 1 && ( // Only show step 1 if not loading
                    <fieldset>
                    <input 
                      type="text" 
                      id="name" 
                      name="name" 
                      value={name} 
                      placeholder='Enter your Full Name' 
                      onChange={e => onChange(e)}
                      required 
                    />

                    <input 
                      type="email" 
                      id="email" 
                      name="email" 
                      value={email} 
                      placeholder="Enter a Valid Email Address"
                      onChange={e => onChange(e)}
                      required 
                    />    

                    <input 
                      type="password" 
                      id="password" 
                      name="password" 
                      value={password} 
                      placeholder="Enter your password"
                      onChange={e => onChange(e)}
                      required 
                    />    

                    <input 
                      type="password" 
                      id="password2" 
                      name="password2" 
                      value={password2} 
                      placeholder="Confirm your password"
                      onChange={e => onChange(e)}
                      required 
                    />    

                    {/* Trigger the next step when valid */}
                    {isStepOneValid() && (
                        <div className='register-check'>
                            <img src='/images/register-check.gif' alt='krafsync' />
                            {handleNextStep()}
                        </div>
                    )}

                  </fieldset>
                )}

                {/* Loading state */}
                {loading && <div className="register-spinner">
                  <img src='/images/register-loading.gif' alt='krafsync' />
                </div>} 

                {/* Step 2: Company Details */}
                {step === 2 && (
                    
                    <fieldset>
                        <div className="back-step-button" onClick={onPreviousStep}><HiArrowNarrowLeft /></div>
                    <input 
                      type="text" 
                      id="companyName" 
                      name="companyName" 
                      value={companyName} 
                      placeholder='Organization Name' 
                      onChange={e => onChange(e)}
                      required 
                    />

                    <input 
                      type="text" 
                      id="companyNo" 
                      name="companyNo" 
                      value={companyNo} 
                      placeholder="Organization's Registration Number"
                      onChange={e => onChange(e)}
                      required 
                    />    

                    <button type="submit" className="submit-button">Register</button>
                  </fieldset>
                )}
  
                  <div className="privacy-link">
                    <div>By continuing, you agree to our User Terms of Service and Customer Terms of Service, and acknowledge that you have read the Privacy Policy to learn how we collect, use and share your data.</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </Fragment>
    );
};

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, register })(Register);
