import React, { Fragment, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import Alert from '../layout/Alert'; 
import { login } from '../../actions/auth';
import { Link } from 'react-router-dom';

import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = ({ setAlert, login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  
  const [showPassword, setShowPassword] = useState(false);  // State for password visibility
  const [passwordFocused, setPasswordFocused] = useState(false);  // State for tracking input focus

  const { email, password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    try {
      await login(email, password); // Assuming login returns a Promise
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setAlert('Invalid email or password', 'danger');
      } else {
        setAlert('An error occurred. Please try again.', 'danger');
      }
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setTimeout(() => {
      setPasswordFocused(false);
    }, 200);  // Short delay to allow click on the icon
  };

  const handleIconMouseDown = e => {
    e.preventDefault();  // Prevents the input from losing focus when clicking the icon
  };

  return (
    <Fragment>
      <main className="index-main roboto-regular">
        <div className='lp-nav-container'>
          <div className='lp-nav-innercont'>
            <Link to='/' className="lp-nav-logo">
              <img src='/images/krafsync_logo.png' alt='krafsync'/>
            </Link>
          </div>
        </div>
        <div className='divider'></div>
        <div className='login-main'>
          <div className='login-container'>
            <Alert />
            <form className="login-form roboto-bold" onSubmit={onSubmit}>
              <div className="login-form-container">
                <fieldset>
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={email} 
                    placeholder='Email Address' 
                    onChange={onChange} 
                    required 
                  />

                  <div className='lfc-pwd-container'>
                    <input 
                      type={showPassword ? 'text' : 'password'} 
                      id="password" 
                      name="password" 
                      value={password} 
                      placeholder='Password' 
                      onChange={onChange} 
                      required 
                      onFocus={handlePasswordFocus}   // Show button on focus
                      onBlur={handlePasswordBlur}     // Hide button on blur
                    />

                    {passwordFocused && (
                      <button
                        type="button"
                        onMouseDown={handleIconMouseDown}  // Prevent losing focus on icon click
                        onClick={togglePasswordVisibility}
                        id="password-toggle-button"
                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    )}
                  </div>

                  <button type="submit" className="roboto-bold">Log In</button>

                  <div className="reset-link-wrapper">
                    <Link to="/Reset" className="reset-link">Forgotten Your Password?</Link>
                  </div>
                </fieldset>

                <div className="lfc-btm">
                  <Link to='/Register' className='register-link'>Create an account</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, login })(Login);
