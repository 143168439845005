import { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Navsection from './components/layout/Navsection';
import Landing from './components/layout/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Reset from './components/auth/Reset';
import ResetPassword from './components/auth/ResetPassword';
import Profile from './components/profile/Profile.jsx';
import UserProfile from './components/profile/UserProfile';
import EditProfile from './components/profile/EditProfile';
import Dashboard from './components/dashboard/Dashboard';
import RegisterUser from './components/auth/RegisterUser';
import Admin from './components/dashboard/Admin';
import ManageUser from './components/dashboard/ManageUser';
import UserRemuneration from './components/dashboard/UserRemuneration';
import EditUser from './components/dashboard/EditUser.js';
import LeaveApplication from './components/dashboard/LeaveApplication';
import LeaveForm from './components/dashboard/LeaveForm';
import LeaveRecords from './components/dashboard/LeaveRecords';
import AdminLeaveRecords from './components/dashboard/AdminLeaveRecords';
import LeaveSetting from './components/dashboard/LeaveSetting';
import PublicHoliday from './components/dashboard/PublicHoliday';
import LeaveManagement from './components/dashboard/LeaveManagement';
import UpdateLeaveForm from './components/dashboard/UpdateLeaveForm';
import People from './components/dashboard/People';
import EditCompany from './components/company/EditCompany';
import PrivateRoute from './components/routing/PrivateRoute';
import AdminRoute from './components/routing/AdminRoute';
import LeaveApproved from './components/redirects/LeaveApproved';
import LeaveRejected from './components/redirects/LeaveRejected.js';
import Wip from './components/layout/Wip';
import AttendanceDashboard from './components/dashboard/AttendanceDashboard.js';
import Editor from './components/workflow/Editor';


// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import './App.css';
import setAuthToken from './utils/setAuthToken';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}        

const App = () => { 
  const location = useLocation();

  useEffect(() => {
    store.dispatch(loadUser()); 
  }, []);

  // Define regular expressions for dynamic paths
  const dynamicPaths = [
    /^\/reset\/[^/]+$/ // Matches /ResetPassword/:token
  ];

  // Determine if the Navbar and Navsection should be shown
  const shouldShowNav = !['/', '/Login', '/Register', '/LeaveApproved', '/LeaveRejected', '/Reset'].includes(location.pathname) &&
    !dynamicPaths.some(pattern => pattern.test(location.pathname));



  return (
    <Provider store={store}>
      <Fragment>
        {shouldShowNav && <Navbar />}
        {shouldShowNav && <Navsection />}
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/LeaveApproved" element={<LeaveApproved />} />
          <Route path="/LeaveRejected" element={<LeaveRejected />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Reset" element={<Reset />} />
          <Route path="/reset/:token" element={<ResetPassword />} />
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/admin" element={<AdminRoute element={Admin} />} />
          <Route path="/new-user" element={<AdminRoute element={RegisterUser} />} />
          <Route path="/people" element={<PrivateRoute element={People} />} />
          <Route path="/leave-form/:id" element={<PrivateRoute element={LeaveApplication} />} />
          <Route path="/leaveform/:id" element={<PrivateRoute element={LeaveForm} />} />
          <Route path="/leave-records/:id" element={<PrivateRoute element={LeaveRecords} />} />
          <Route path="/allleave-records" element={<PrivateRoute element={AdminLeaveRecords} />} />
          <Route path="/leave-setting/:id" element={<AdminRoute element={LeaveSetting} />} />
          <Route path="/Public-Holidays" element={<PrivateRoute element={PublicHoliday} />} />
          <Route path="/leave-setting" element={<AdminRoute element={LeaveManagement} />} />
          <Route path="/admin/:id" element={<AdminRoute element={ManageUser} />} />
          <Route path="/admin/remuneration/:id" element={<AdminRoute element={UserRemuneration} />} />
          <Route path="/admin/ed/:id" element={<AdminRoute element={EditUser} />} />
          <Route path="/admin/leave/:id" element={<AdminRoute element={UpdateLeaveForm} />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/user-profile/:id" element={<UserProfile />} />
          <Route path="/edit-profile/:id" element={<EditProfile />} />
          <Route path="/admin/edit-company" element={<AdminRoute element={EditCompany} />} />
          <Route path="/attendance" element={<PrivateRoute element={AttendanceDashboard} />} />
          <Route path="/comingsoon" element={<Wip />} />
          <Route path="/editor" element={<PrivateRoute element={Editor} />} />
        </Routes>
      </Fragment>
    </Provider> 
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;