import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const PersonalDetails = ({ user }) => {
    const [formData, setFormData] = useState({
        userName: '',
        email: '',
        AddressLine1: '',
        AddressLine2: '',
        AddressLine3: '',
        contact: '',
        EmergencyContact: '',
        ecName: '',
        ecRelationship: ''
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (user) {
            setFormData({
                userName: user.name || '',
                email: user.email || '',
                AddressLine1: user.AddressLine1 || '',
                AddressLine2: user.AddressLine2 || '',
                AddressLine3: user.AddressLine3 || '',
                contact: user.contact || '',
                EmergencyContact: user.EmergencyContact || '',
                ecName: user.ecName || '',
                ecRelationship: user.ecRelationship || ''
            });
        }
    }, [user]);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handlePersonalDetails = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const res = await axios.put('/api/users/edit-profile/personal-details', formData, config);

            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('User personal details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update user details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors);
            } else {
                console.error('Error updating user details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">Personal Details</div>
            <div className="ep-li-desc">We use this information to verify your identity and to keep our community safe. Your information is mostly shared with your Employer with any confidential details encrypted on both ends.</div>

            <form className="ep-form" onSubmit={handlePersonalDetails}>
                <label htmlFor="userName">Name:</label>
                <input type="text" id="userName" name="userName" value={formData.userName} onChange={onChange} />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={onChange} />

                <label htmlFor="Address">Address:</label>
                <input type="text" id="AddressLine1" name="AddressLine1" placeholder="Address Line 1" value={formData.AddressLine1} onChange={onChange} required />
                <input type="text" id="AddressLine2" name="AddressLine2" placeholder="Address Line 2" value={formData.AddressLine2} onChange={onChange} required />
                <input type="text" id="AddressLine3" name="AddressLine3" placeholder="Address Line 3" value={formData.AddressLine3} onChange={onChange} />

                <label htmlFor="contact">Contact:</label>
                <input type="text" id="contact" name="contact" value={formData.contact} onChange={onChange} placeholder='Please key in your Contact e.g 012-XXXXXXX' />

                <div className='m-y-divider'></div>

                <div className="ep-li-title">Emergency Contact</div>
                <div className="ep-li-desc">Details on the relevant contact and corresponding relationship in the event of an emergency</div>

                <label htmlFor="ecName">Name:</label>
                <input type="text" id="ecName" name="ecName" value={formData.ecName} onChange={onChange} />

                <label htmlFor="ecRelationship">Relationship:</label>
                <input type="text" id="ecRelationship" name="ecRelationship" value={formData.ecRelationship} onChange={onChange} />

                <label htmlFor="EmergencyContact">Emergency Contact:</label>
                <input type="number" id="EmergencyContact" name="EmergencyContact" value={formData.EmergencyContact} onChange={onChange} placeholder='Please key in your Emergency Contact e.g 012-XXXXXXX' />

                <div className='m-y-divider'></div>

                <button type="submit" className='ep-btn'>Update Personal Details</button>
            </form>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PersonalDetails;

