import axios from 'axios';
import {
    GET_COMPANY,
    COMPANY_ERROR
} from './types';
import setAuthToken from '../utils/setAuthToken';

// Get Company 
export const getCompany = () => async dispatch => {
    if(localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get('/api/company');

        dispatch({
            type: GET_COMPANY,
            payload: res.data
        });
    } catch(err) {
        console.error('Error loading company', err); 
        dispatch({
            type: COMPANY_ERROR
        });
    }
};