import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../actions/profile';
import Spinner from '../layout/Spinner';
import PersonalDetails from './PersonalDetails';
import UpdateProfile from './UpdateProfile';
import PasswordAndSecurity from './PasswordAndSecurity';

const EditProfile = ({ getCurrentProfile, auth: { user }, profile }) => {
    const [activeOption, setActiveOption] = useState('option1');

    useEffect(() => {
        getCurrentProfile();
    }, [getCurrentProfile]);

    if (!user || !profile) {
        return <Spinner />;
    }

    const getButtonStyle = (option) => ({
        backgroundColor: activeOption === option ? 'rgb(52, 89, 154)' : 'transparent',
        color: activeOption === option ? 'white' : 'black',
    });

    return (
        <Fragment>
            <main className="ep-main roboto-regular">
                <div className="ep-container">
                    <div className="ep-left-wrapper">
                        <div className="ep-left-item">
                            <div className="ep-li-title">Accounts Centre</div>
                            <div className="ep-li-desc">Manage your connected experiences and account settings across our platforms</div>

                            <div onClick={() => setActiveOption('option1')} style={getButtonStyle('option1')} className="ep-li-link">
                                <span><img src="/images/user-3-16.ico" alt="profile-icon" /></span>
                                <span>Personal Details</span>
                            </div>

                            <div onClick={() => setActiveOption('option2')} style={getButtonStyle('option2')} className="ep-li-link">
                                <span><img src="/images/edit-user-16.ico" alt="profile-icon" /></span>
                                <span>Update Profile</span>
                            </div>

                            <div onClick={() => setActiveOption('option3')} style={getButtonStyle('option3')} className="ep-li-link">
                                <span><img src="/images/padlock-4-16.ico" alt="profile-icon" /></span>
                                <span>Password & Security</span>
                            </div>
                        </div>
                    </div>

                    <div className="ep-right-wrapper">
                        {activeOption === 'option1' && <PersonalDetails user={user} />}
                        {activeOption === 'option2' && <UpdateProfile profile={profile} user={user} />}
                        {activeOption === 'option3' && <PasswordAndSecurity />}
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default connect(mapStateToProps, { getCurrentProfile })(EditProfile);
