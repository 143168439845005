import { 
    RESETPASSWORD_REQUEST,
    RESETPASSWORD_SUCCESS, 
    RESETPASSWORD_FAIL 
} from '../actions/types';

const initialState = {
    loading: false,
    success: false,
    error: null
};

const resetReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case RESETPASSWORD_REQUEST:
            return {
                ...state,
                loading: true, // Set loading to true when request starts
                success: false,
                error: null // Clear any previous errors
            };
        case RESETPASSWORD_SUCCESS:
            return {
                ...state,
                loading: false, // Set loading to false on success
                success: true,
                error: null // Clear any previous errors
            };
        case RESETPASSWORD_FAIL:
            return {
                ...state,
                loading: false, // Set loading to false on failure
                success: false,
                error: payload || 'Failed to reset password' // Show error
            };
        default:
            return state;
    }
};

export default resetReducer;