import React, { useState, useRef, useEffect } from 'react';
import TableSizeSelector from './TableSizeSelector';

// Icons
import { MdFormatListBulleted } from "react-icons/md";
import { GoListOrdered } from "react-icons/go";
import { FaEraser, FaRegTrashAlt } from "react-icons/fa";
import { LuBold, LuItalic } from "react-icons/lu";
import { TfiUnderline } from "react-icons/tfi";
import { FaArrowRotateLeft, FaStrikethrough } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { BsTable } from "react-icons/bs";
import { AiOutlineInsertRowRight, AiOutlineInsertRowLeft, AiOutlineInsertRowAbove, AiOutlineInsertRowBelow, AiOutlineMergeCells, AiOutlineSplitCells } from "react-icons/ai";
import { RiDeleteColumn, RiDeleteRow } from "react-icons/ri";
import { RxImage } from "react-icons/rx";
import { PiHighlighterBold } from "react-icons/pi";
import { MdOutlineFormatAlignLeft, MdFormatAlignJustify, MdFormatAlignCenter, MdFormatAlignRight } from "react-icons/md";
import { GoTasklist } from "react-icons/go";
import { TfiLayoutColumn2, TfiLayoutColumn3, TfiLayoutColumn4 } from "react-icons/tfi";


const MenuBar = ({ editor }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedHeading, setSelectedHeading] = useState(1);
  const [showTableSelector, setShowTableSelector] = useState(false);
  const [showTableControls, setShowTableControls] = useState(false);
  const fileInputRef = useRef(null); // Ref to trigger file input
  const [num, setNum] = useState(2); // Default to 2 columns, or set dynamically

  const tableControlsRef = useRef(null); // Ref for table controls
  const menuBarRef = useRef(null); // Ref for the entire menu bar

  // Detect when the editor contains a table and set table controls visibility
  useEffect(() => {
    if (!editor) return; // Early return if editor is not available
  
    const handleClickInsideTable = () => {
      const isInsideTable = editor.isActive('table');
      setShowTableControls((prevState) => {
        if (prevState === isInsideTable) {
          return prevState; // Do nothing if the state doesn't need to change
        }
        return isInsideTable; // Update state only if it has changed
      });
    };
  
    // Trigger the table control visibility when the editor is focused on a table or other content
    editor.on('selectionUpdate', handleClickInsideTable);
  
    return () => {
      editor.off('selectionUpdate', handleClickInsideTable); // Cleanup listener on component unmount
    };
  }, [editor]);
  

  // Close table controls when clicking outside the menu bar or table
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tableControlsRef.current &&
        !tableControlsRef.current.contains(event.target) &&
        menuBarRef.current &&
        !menuBarRef.current.contains(event.target)
      ) {
        setShowTableControls((prevState) => {
          if (!prevState) return prevState; // If already hidden, do nothing
          return false; // Hide table controls
        });
      }
    };       
  
    // Attach event listener to close table controls when clicked outside
    document.addEventListener('mousedown', handleClickOutside);
  
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);   

  const addImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        editor.chain().focus().setImage({
          src: reader.result,
        }).run();
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleTaskList = () => {
    if (!editor) return;
  
    // Check if the task list is currently active
    if (editor.isActive('taskList')) {
      // If active, remove the task list
      editor.chain().focus().clearNodes().run();
    } else {
      // If not active, add the task list
      editor.chain().focus().toggleTaskList().run();
    }
  };
  
  

  // Handle inserting table after size is selected
  const handleTableInsert = (rows, cols) => {
    editor.chain().focus().insertTable({ rows, cols }).run();
    setShowTableControls(true); // Show table controls after inserting table
    setShowTableSelector(false); // Hide table size selector
  };

  // Handle heading changes
  const handleHeadingChange = (level) => {
    setSelectedHeading(level);
    if (level === 'paragraph') {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level }).run();
    }
    setDropdownOpen(false);
  };

  return (
    <div className="control-group roboto-regular" ref={menuBarRef}>
      <div className="button-group">
        <div className="text-editor-dropdown">
          <div className="text-editor-dropdown-toggle" onClick={() => setDropdownOpen(!isDropdownOpen)}>
            <span>
              {selectedHeading === 'paragraph' ? 'Paragraph' : selectedHeading === 1 ? 'Header' : `H${selectedHeading}`}
            </span>
            <span><IoIosArrowDown /></span>
          </div>
          {isDropdownOpen && (
            <div className="text-editor-dropdown-menu">
              <div onClick={() => handleHeadingChange('paragraph')} className="text-editor-dropdown-item">
                Paragraph
              </div>
              {[1, 2, 3, 4, 5, 6].map((level) => (
                <div
                  key={level}
                  onClick={() => handleHeadingChange(level)}
                  className={`text-editor-dropdown-item ${selectedHeading === level ? 'text-editor-is-active' : ''}`}
                >
                  {level === 1 ? 'Header' : `H${level}`}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="menu-cont mc-color">
    {/* Color Picker Input */}
    <input
        type="color"
        onInput={(event) => {
            const color = event.target.value;
            editor.chain().focus().setColor(color).run(); 
        }}
        value={editor.getAttributes('textStyle').color || '#000000'} // Default to black if no color
        data-testid="setCustomColor"
        className="color-picker"
    />

    {/* Color Dropdown */}
    <select
        className="color-dropdown"
        onChange={(event) => {
            const color = event.target.value;
            editor.chain().focus().setColor(color).run(); 
        }}
        value={editor.getAttributes('textStyle').color || '#000000'} // Ensure dropdown reflects the selected color
        data-testid="setColorDropdown"
    >
        {/* Custom color option that dynamically changes based on color picker */}
        <option 
            value={editor.getAttributes('textStyle').color || '#000000'} 
            className="color-custom"
            style={{ backgroundColor: editor.getAttributes('textStyle').color || '#000000' }} 
        >
            {editor.getAttributes('textStyle').color || '#000000'} 
        </option>

        {/* Other color options */}
        <option value="#000000" className="color-black">Black</option>
        <option value="#958DF1" className="color-purple">Purple</option>
        <option value="#F98181" className="color-red">Red</option>
        <option value="#FBBC88" className="color-orange">Orange</option>
        <option value="#FAF594" className="color-yellow">Yellow</option>
        <option value="#70CFF8" className="color-blue">Blue</option>
        <option value="#94FADB" className="color-teal">Teal</option>
        <option value="#B9F18D" className="color-green">Green</option>
    </select>
</div>



        {/* Formatting Buttons */}
        <div className='menu-cont'>
          <div onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'is-active' : ''}>
            <LuBold />
          </div>
          <div onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'is-active' : ''}>
            <LuItalic />
          </div>
          <div onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive('underline') ? 'is-active' : ''}>
            <TfiUnderline />
          </div>
          <div onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive('strike') ? 'is-active' : ''}>
            <FaStrikethrough />
          </div>
        </div>
                  

        <div className='menu-cont'>
          <div onClick={() => editor.chain().focus().setTextAlign('left').run()} className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}>
          <MdOutlineFormatAlignLeft />
          </div>
          <div onClick={() => editor.chain().focus().setTextAlign('center').run()} className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}>
          <MdFormatAlignCenter />
          </div>
          <div onClick={() => editor.chain().focus().setTextAlign('right').run()} className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}>
          <MdFormatAlignRight />
          </div>
          <div onClick={() => editor.chain().focus().setTextAlign('justify').run()} className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}>
          <MdFormatAlignJustify />
          </div>
        </div>      

                {/* Table Buttons */}
                <div className="menu-cont">
          <div onClick={() => setShowTableSelector(!showTableSelector)}>
            <BsTable />
          </div>
          {showTableSelector && (
            <div className="table-selector-popup">
              <TableSizeSelector onSelect={handleTableInsert} />
            </div>
          )}

              {/* Image insert button */}
        <div onClick={() => fileInputRef.current.click()}> {/* Open file selector */}
        <RxImage style={{ fontSize: '1.3rem' }} />

        </div>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={addImage}
        />

          <div onClick={() => editor.chain().focus().toggleHighlight().run()} className={editor.isActive('highlight') ? 'is-active' && 'highlightis-active' : ''}>
            <PiHighlighterBold />
          </div>
        </div>

        <div className='menu-cont'>

          <div onClick={toggleTaskList}>
            <GoTasklist />
          </div>

          <div onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') ? 'is-active' : ''}>
            <MdFormatListBulleted />
          </div>
          <div onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive('orderedList') ? 'is-active' : ''}>
            <GoListOrdered />
          </div>
        </div>

        <div className='menu-cont'>
          {/* 2 Columns */}
          <div
            onClick={() => editor.chain().focus().setColumns(2).run()}
            className={editor.isActive('column-block', { columns: 2 }) ? 'is-active' : ''}
          >
            <TfiLayoutColumn2 />
          </div>

          {/* 3 Columns */}
          <div
            onClick={() => editor.chain().focus().setColumns(3).run()}
            className={editor.isActive('column-block', { columns: 3 }) ? 'is-active' : ''}
          >
            <TfiLayoutColumn3 />
          </div>

          {/* 4 Columns */}
          <div
            onClick={() => editor.chain().focus().setColumns(4).run()}
            className={editor.isActive('column-block', { columns: 4 }) ? 'is-active' : ''}
          >
            <TfiLayoutColumn4 />
          </div>
        </div>

        <div className='menu-cont mc-last'>
          <div onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}>
            <FaEraser />
          </div>
          <div onClick={() => editor.chain().focus().undo().run()}>
            <FaArrowRotateLeft />
          </div>
        </div>
      </div>

      {/* Only render sub-menu if it has visible content */}
      {showTableControls && (
        <div className="sub-menu">
          <div className="table-controls" ref={tableControlsRef}>
            <div onClick={() => editor.chain().focus().addColumnBefore().run()} disabled={!editor.can().addColumnBefore()}>
              <AiOutlineInsertRowRight />
            </div>
            <div onClick={() => editor.chain().focus().addColumnAfter().run()} disabled={!editor.can().addColumnAfter()}>
              <AiOutlineInsertRowLeft />
            </div>
            <div onClick={() => editor.chain().focus().deleteColumn().run()} disabled={!editor.can().deleteColumn()}>
              <RiDeleteColumn />
            </div>
            <div onClick={() => editor.chain().focus().addRowBefore().run()} disabled={!editor.can().addRowBefore()}>
              <AiOutlineInsertRowAbove />
            </div>
            <div onClick={() => editor.chain().focus().addRowAfter().run()} disabled={!editor.can().addRowAfter()}>
              <AiOutlineInsertRowBelow />
            </div>
            <div onClick={() => editor.chain().focus().deleteRow().run()} disabled={!editor.can().deleteRow()}>
              <RiDeleteRow />
            </div>
            <div onClick={() => editor.chain().focus().mergeCells().run()} disabled={!editor.can().mergeCells()}>
              <AiOutlineMergeCells />
            </div>
            <div onClick={() => editor.chain().focus().splitCell().run()} disabled={!editor.can().splitCell()}>
              <AiOutlineSplitCells />
            </div>
            <div onClick={() => editor.chain().focus().deleteTable().run()} disabled={!editor.can().deleteTable()}>
              <FaRegTrashAlt />
            </div>
          </div>  
        </div>
      )}
    </div> 
  );
};      

export default MenuBar; 






