import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const UserPermissions = ({ user }) => {
    const [formData, setFormData] = useState({
        role: ''
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (user) {
            setFormData({
                role: user.role || ''
            });
        }
    }, [user]);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handlePersonalDetails = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const res = await axios.put(`/api/users/update/${user._id}`, formData, config);

            if (res.status === 200 || res.status === 201) {
                setFeedbackMessage('User personal details updated successfully.');
            } else {
                setFeedbackMessage('Failed to update user details.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setValidationErrors(error.response.data.errors || []);
            } else {
                console.error('Error updating user details:', error);
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">Permissions</div>
            <div className="ep-li-desc">Set Admin Permissions for User</div>

            <form className="ep-form" onSubmit={handlePersonalDetails}>
            

                <label htmlFor="role">Admin Privilege:</label>
                <br />
                <div className="role-container">
                    <input 
                        type="radio" 
                        id="user" 
                        name="role" 
                        value="user" 
                        checked={formData.role === 'user'} 
                        onChange={onChange} 
                        required 
                    />
                    <label className="role-option" htmlFor="user">User Role</label>

                    <input 
                        type="radio" 
                        id="admin" 
                        name="role" 
                        value="admin" 
                        checked={formData.role === 'admin'} 
                        onChange={onChange} 
                        required 
                    />
                    <label className="role-option" htmlFor="admin">Admin Role</label>
                </div>
                <div className='m-y-divider'></div>

                <button type="submit" className='ep-btn'>Submit</button>
            </form>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default UserPermissions;
