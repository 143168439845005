import React, { useState, useEffect, Fragment } from 'react';
import { getUser } from '../../actions/users';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import setAuthToken from '../../utils/setAuthToken';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const UpdateLeaveForm = ({ getUser, auth, user: { user, loading } }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  // Function to determine the correct avatar URL
  const loadAvatar = (user) => {
    if (user) {
    if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
        // Use the Gravatar URL directly
        return `https:${user.avatar}`;
    } else if (user.avatarUrl) {
        // Use the presigned URL directly
        return user.avatarUrl;
    } else {
        // Default avatar if no avatar URL is present
        return '/path-to-default-avatar.jpg';
    }
    } else {
    // Default avatar if no user
    return '/path-to-default-avatar.jpg';
    }
};

  // Initial load to get user data
  useEffect(() => {
    getUser(id); // Pass the user ID to the getUser action
    loadAvatar();
  }, [getUser, id]);

  const [leave, setLeave] = useState({
    annual: '',
    medical: '',
    emergency: '',
    study: '',
    replacement: '',
    compassionate: '',
    marriage: '',
    maternity: '',
    hospitalisation: '',
    unpaid:''
  });

  // Effect for handling user data update
  useEffect(() => {
    if (user) {
      setLeave({
        annual: user.leave?.annual !== undefined ? user.leave.annual : '',
        medical: user.leave?.medical !== undefined ? user.leave.medical : '',
        emergency: user.leave?.emergency !== undefined ? user.leave.emergency : '',
        study: user.leave?.study !== undefined ? user.leave.study : '',
        replacement: user.leave?.replacement !== undefined ? user.leave.replacement : '',
        compassionate: user.leave?.compassionate !== undefined ? user.leave.compassionate : '',
        marriage: user.leave?.marriage !== undefined ? user.leave.marriage : '',
        maternity: user.leave?.maternity !== undefined ? user.leave.maternity : '',
        hospitalisation: user.leave?.hospitalisation !== undefined ? user.leave.hospitalisation : '',
        unpaid: user.leave?.unpaid !== undefined ? user.leave.unpaid : ''
      });
    }
  }, [user]);

  if (loading) {
    return <Spinner />;
  }

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the leave state only if value is valid or an empty string

    const newValue = value === '' ? '0' : Number(value) || value;

    setLeave(prevLeave => ({
      ...prevLeave,
      [name]: newValue
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setAuthToken(localStorage.getItem('token'));
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
      }
    };
    
    // Send leave data directly
    const body = JSON.stringify(leave);
    
    console.log('Sending request to:', `/api/users/admin/leave/${id}`);
    console.log('Request body:', body);
    
    try {
      const res = await axios.put(`/api/users/admin/leave/${id}`, body, config);
    
      if (res.status === 200 || res.status === 201) {
        console.log('User leave updated successfully:', res.data);
        alert('Leave has been updated successfully!');
      } else {
        console.error('Failed to update leave:', res.data);
        alert('Failed to update leave!');
      }
    } catch (error) {
      console.error('Error updating leave:', error);
    }
  };
  
  return (
    <Fragment>
      {user ? (
        <main className="roboto-regular main-top">
        <div className="usermng-pfp">
            <div className="usermng-title">Leave Management</div>
            <div className="usermng-user-particulars-container">
                <div className="usermng-avatar-cont">
                    <img src={loadAvatar(user)} alt="user-avatar" />
                </div>
                <div className="usermng-particular">
                    <div>
                        <div>{user.name}</div>
                        <div>{user.jobposition}</div>
                    </div>  
                    <div>
                        <div>Email: {user.email}</div>
                        <div>Contact No: {user.contact}</div>
                    </div>
                </div>
            </div>

           <div className="usermng-leave-actions">
            <div>
              <form onSubmit={handleSubmit} className="usermng-leave-form">
                <div>
                  <label>
                    Annual:
                    <input
                      type="number"
                      name="annual"
                      value={leave.annual}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Medical/Sick:
                    <input
                      type="number"
                      name="medical"
                      value={leave.medical}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Emergency:
                    <input
                      type="number"
                      name="emergency"
                      value={leave.emergency}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div>
                  <label>
                     Study:
                    <input
                      type="number"
                      name="study"
                      value={leave.study}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Replacement:
                    <input
                      type="number"
                      name="replacement"
                      value={leave.replacement}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Compassionate:
                    <input
                      type="number"
                      name="compassionate"
                      value={leave.compassionate}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Marriage:
                    <input
                      type="number"
                      name="marriage"
                      value={leave.marriage}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Maternity/Paternity:
                    <input
                      type="number"
                      name="maternity"
                      value={leave.maternity}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Unpaid:
                    <input
                      type="number"
                      name="unpaid"
                      value={leave.unpaid}
                      onChange={handleChange}
                      className='roboto-bold'
                      readOnly
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Hospitalisation:
                    <input
                      type="number"
                      name="hospitalisation"
                      value={leave.hospitalisation}
                      onChange={handleChange}
                      className='roboto-bold'
                    />
                  </label>
                </div>
                <div></div>
                <div></div>
                <button type="submit">Update Leave</button>
              </form>
            </div>
           </div>
        </div>
    </main>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

UpdateLeaveForm.propTypes = {
  getUser: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired,
  user: PropTypes.shape({
    user: PropTypes.shape({
      leave: PropTypes.shape({
        annual: PropTypes.number,
        medical: PropTypes.number,
        maternity: PropTypes.number
      })
    }),
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.users,
});

export default connect(mapStateToProps, { getUser })(UpdateLeaveForm);
