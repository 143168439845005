import React, { Fragment} from 'react';
import TextEditor from '../component/TextEditor';

const Editor = () => {

    return (
        <Fragment>
            <main>
                <div className="editor-layout">
                    <TextEditor />
                </div>
            </main>
        </Fragment>
    );
};

export default Editor;
