import React, {Fragment, useState} from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { sendResetLink } from '../../actions/auth';
import PropTypes from 'prop-types';
import Alert from '../layout/Alert'; 
import { Link } from 'react-router-dom';

const Reset = ({ setAlert, sendResetLink }) => {
    const [formData, setFormData] = useState({
        email: ''
    });

    const { email } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        sendResetLink(email);
    };

    return (
    <Fragment>
        <main className="index-main roboto-regular">
            <div className='lp-nav-container'>
            <div className='lp-nav-innercont'>
                <Link to='/' className="lp-nav-logo">
                <img src='/images/krafsync_logo.png' alt='krafsync'/>
                </Link>
            </div>
            </div>
            <div className='divider'></div>
            <div className='login-main'>
            <div className='login-container'>
                <Alert />
                <form className="login-form roboto-bold" onSubmit={e => onSubmit(e)}>
                <div className="login-form-container">
                    <fieldset>
                    <input 
                        type="email" 
                        id="email" 
                        name="email" 
                        value={email} 
                        placeholder='Email Address' 
                        onChange={e => onChange(e)} 
                        required 
                    />


                    <button type="submit" className="roboto-bold">Submit</button>
                    </fieldset>

                    <div className="lfc-btm">
                    <Link to='/Register' className='register-link'>Create an account</Link>
                    </div>
                </div>
                </form>
            </div>
            </div>
        </main>
    </Fragment>
    );
};
           
Reset.propTypes = {
    setAlert: PropTypes.func.isRequired,
    sendResetLink: PropTypes.func.isRequired, // Ensure propTypes validation
};

export default connect(null, { setAlert, sendResetLink })(Reset);